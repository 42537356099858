import React from "react";
import { ValetParking as vp } from "./Content"

export default class ValetParking extends React.Component {
    render() {
        let heroImage1x = "image/valet-parking-cover.png";
        let heroImage2x = "image/valet-parking-cover@2x.png";
        let webDesign = "image/web-design.png";
        let mobileApps = "image/mobile-apps.png";
        let customSoftwareSolutions = "image/custom-software-solutions.png";
        let parkingSofwares = "image/parking-sofwares.png";
        let tickMark = "image/tick-mark.png";
        let valetHowWorks = "image/valet-how-it-works.png";

        let iconv = "image/icon-v.png";
        let iconb = "image/icon-b.png";
        let icono = "image/icon-o.png";
        let iconaj = "image/icon-aj.png";
        let iconaf = "image/icon-af.png";
        let iconap = "image/icon-ap.png";
        let iconai = "image/icon-ai.png";
        let iconu = "image/icon-u.png";
        let iconad = "image/icon-ad.png";
        let iconq = "image/icon-q.png";
        let iconac = "image/icon-ac.png";

        return (
            <div className="valet-parking-section">
                <section className="hero-section">
                    <div className="hero-image">
                        <img className="hide-md" src={heroImage1x} alt="" />
                        <img className="hide-xs" src={heroImage2x} alt="" />
                    </div>
                    <div className="base-div">
                        <span>
                            <h1 className="header1">Valet Parking</h1>
                            <p className="paragraph1">
                                {vp["Valet Parking"]}
                            </p>
                        </span>
                    </div>
                </section>
                <section className="problem-solution-section">
                    <div className="base-div">
                        <section className="problem-section">
                            <h3 className="header3">What Problems Valet Parking Lot Owners faces?</h3>
                            <p className="paragraph1">{vp["What Problem Valet parking Operator faces?"].header}</p>
                            <ul className="list">
                                {vp["What Problem Valet parking Operator faces?"].items.map(i=>{
                                    return (
                                        <li className="list-item paragraph1">{i}</li>
                                    )
                                })}                                
                            </ul>
                        </section>
                        <section className="solution-section">
                            <h3 className="header3">How our solutions will overcome those problems?</h3>
                            <p className="paragraph1">{vp["How our Solution will over come those problems?"].header}</p>
                            <ul className="list">
                                {vp["How our Solution will over come those problems?"].items.map(i=>{
                                    return (
                                        <li className="list-item paragraph1">{i}</li>
                                    )
                                })}
                            </ul>
                        </section>
                    </div>
                </section>
                <section className="what-section">
                    <div className="head-base-div ">
                        <h2 className="header2">What we do?</h2>
                        <div className="text-element"></div>
                        <p className="paragraph1">
                            {vp["What do We Do?"]}
                        </p>
                    </div>
                    <div className="base-div ">
                        <ul className="list">
                            <li className="list-item">
                                <div className="icon-holder">
                                    <img src={webDesign} alt="" />
                                </div>
                                <h5 className="header5">Website Design & Development</h5>
                                <p className="paragraph3">
                                    {vp["Website Design & Development"]}
                                </p>
                            </li>
                            <li className="list-item">
                                <div className="icon-holder">
                                    <img src={mobileApps} alt="" />
                                </div>
                                <h5 className="header5">iOS & Android Mobile Apps</h5>
                                <p className="paragraph3">
                                    {vp["iOS & Android Mobile Apps"]}
                                </p>
                            </li>
                            <li className="list-item">
                                <div className="icon-holder">
                                    <img src={customSoftwareSolutions} alt="" />
                                </div>
                                <h5 className="header5">Custom Software Solutions</h5>
                                <p className="paragraph3">
                                    {vp["Custom Software Solutions"]}
                                </p>
                            </li>
                        </ul>
                    </div>
                </section>
                <section className="image-representation-section">
                    <div className="head-base-div">
                        <h3 className="header3">Valet Parking with PazeTech Solutions</h3>
                        <p className="paragraph2">
                            {vp["How Valet Parking process works?"]}
                        </p>
                    </div>
                    <div className="base-div">
                        <div className="image-holder">
                            <img src={valetHowWorks} alt="" />
                        </div>
                    </div>
                </section>
                <section className="feature-section white-bg">
                    <div className="base-div">
                        <div className="content-holder">
                            <h6 className="header6">What owners / managers get?</h6>
                            <h2 className="header2">Valet Operator Automation</h2>
                            <div className="text-element"></div>
                            <p className="paragraph1">
                                {vp["Valet Operator Automation"].header}
                            </p>
                            <h5 className="header5">Key Features</h5>
                            <ul className="list">
                                {vp["Valet Operator Automation"].items.map(i=>{
                                    return (
                                        <li className="list-item">
                                            <div className="icon-holder">
                                                <img src={tickMark} alt="" />
                                            </div>
                                            <p className="paragraph3">{i}</p>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>

                        <div className="image-holder">
                            <img src={parkingSofwares} alt="" />
                        </div>
                    </div>
                </section>
                <section className="multi-point-section double-list">
                    <div className="head-base-div">
                        <h3 className="paragraph6">Valet Automation Benefits</h3>
                    </div>
                    <div className="base-div">
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconap} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Manage Multiple Drivers</h5>
                                <p className="paragraph3">{vp["Manage Multiple Drivers"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconv} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Revenue Management</h5>
                                <p className="paragraph3">{vp["Revenue Management"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconb} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Manage orders</h5>
                            <p className="paragraph3">{vp["Manage reservation /orders"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconq} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Send Status and Order Details to Customers</h5>
                                <p className="paragraph3">{vp["Send Status and order details to customers"]}</p>
                            </div>
                        </div>
                    </div>
                    <div className="head-base-div">
                        <h3 className="paragraph6">Customer Relationship Management</h3>
                    </div>
                    <div className="base-div">
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconai} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Email / SMS Marketing Automation</h5>
                                <p className="paragraph3">{vp["Marketing Automation"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconu} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Rewards + Points management</h5>
                                <p className="paragraph3">{vp["Rewards & Loyalty Management"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconad} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Gift Card Management</h5>
                                <p className="paragraph3">{vp["Gift Card Management"]}</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="feature-section color-bg">
                    <div className="base-div">
                        <div className="content-holder">
                            <h6 className="header6">What your customers get?</h6>
                            <h2 className="header2">Customer Payment Interface</h2>
                            <div className="text-element"></div>
                            <p className="paragraph1">
                                {vp["Customer Web App"]}
                            </p>
                            <h5 className="header5">Key Features</h5>
                            <ul className="list">
                                <li className="list-item">
                                    <div className="icon-holder">
                                        <img src={tickMark} alt="" />
                                    </div>
                                    <p className="paragraph3">Real-Time Shuttle Tracking</p>
                                </li>
                                <li className="list-item">
                                    <div className="icon-holder">
                                        <img src={tickMark} alt="" />
                                    </div>
                                    <p className="paragraph3">Dispatch monitoring</p>
                                </li>
                                <li className="list-item">
                                    <div className="icon-holder">
                                        <img src={tickMark} alt="" />
                                    </div>
                                    <p className="paragraph3">Manage drivers</p>
                                </li>
                                <li className="list-item">
                                    <div className="icon-holder">
                                        <img src={tickMark} alt="" />
                                    </div>
                                    <p className="paragraph3">Manage vehicles</p>
                                </li>
                            </ul>
                        </div>
                        <div className="image-holder">
                            <img src={parkingSofwares} alt="" />
                        </div>
                    </div>
                </section>
                <section className="multi-point-section color-bg">
                    <div className="head-base-div">
                        <h3 className="paragraph6">Valet Parking Benefits</h3>
                        <p className="paragraph1">{vp["Valet Parking Benefits"]}</p>
                    </div>
                    <div className="base-div">
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={icono} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">One touch car request</h5>
                                <p className="paragraph3">{vp["One touch car request"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconaj} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Customer no need to install any app</h5>
                            <p className="paragraph3">{vp["Request Car Pick up to Valet Parking"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconaf} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Avail Discounts & Rewards</h5>
                            <p className="paragraph3">{vp["Rewards & Loyalty Management2"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconac} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Chat with Customer Service Support Easily</h5>
                                <p className="paragraph3">{vp["Chat w/ Customer Service"]}</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
