import React from "react";
import { OffsiteAirport as oa } from "./Content"

export default class OffSiteAirportParking extends React.Component {
    render() {
        let heroImage1x = "image/off-parking-cover.png";
        let heroImage2x = "image/off-parking-cover@2x.png";
        let webDesign = "image/web-design.png";
        let mobileApps = "image/mobile-apps.png";
        let customSoftwareSolutions = "image/custom-software-solutions.png";
        let offParkingHowWorks = "image/off-parking-how-it-works.png";
        let offParkingPazetech = "image/off-parking-pazetech-how-it-works.png";
        let yourCompetitors = "image/your-competitors.png";
        let tickMark = "image/tick-mark.png";
        let xMmark = "image/x-mark.png";
        let parkingSofwares = "image/parking-sofwares.png";
        let blueskyLogo = "image/bluesky-logo.png";
        let iconz = "image/icon-z.png";
        let iconb = "image/icon-b.png";
        let iconah = "image/icon-h.png";
        let icona = "image/icon-a.png";
        let iconag = "image/icon-ag.png";
        let iconap = "image/icon-ap.png";
        let iconai = "image/icon-ai.png";
        let iconu = "image/icon-u.png";
        let iconad = "image/icon-ad.png";
        // let iconf = "image/icon-f.png";
        // let iconm = "image/icon-m.png";
        // let iconp = "image/icon-p.png";
        // let iconq = "image/icon-q.png";
        // let iconac = "image/icon-ac.png";

        return (
            <div className="offsite-airport-parking-section">
                <section className="hero-section">
                    <div className="hero-image">
                        <img className="hide-md" src={heroImage1x} alt="" />
                        <img className="hide-xs" src={heroImage2x} alt="" />
                    </div>
                    <div className="base-div ">
                        <span>
                            <h1 className="header1">Off-Site Airport Parking Automation</h1>
                            <p className="paragraph1">
                                {oa.OffSiteAirportParking}
                            </p>
                        </span>
                    </div>
                </section>
                <section className="problem-solution-section">
                    <div className="base-div">
                        <section className="problem-section">
                            <h3 className="header3">What Problems Off-Site Parking Lot Owners faces?</h3>
                            <p className="paragraph1">{oa["What Problem Off Site Parking Lot Owners Faces?"].header}</p>
                            <ul className="list">
                                {oa["What Problem Off Site Parking Lot Owners Faces?"].items.map(i=>{
                                    return (<li className="list-item paragraph1">{i}</li>)
                                })}
                            </ul>
                        </section>
                        <section className="solution-section">
                            <h3 className="header3">How our solutions will overcome those problems?</h3>
                            <p className="paragraph1">{oa["How our Solution will over come those problems?"].header}</p>
                            <ul className="list">
                                {oa["How our Solution will over come those problems?"].items.map(i=>{
                                    return (<li className="list-item paragraph1">{i}</li>)
                                })}
                            </ul>
                        </section>
                    </div>
                </section>
                <section className="what-section">
                    <div className="head-base-div ">
                        <h2 className="header2">What we do?</h2>
                        <div className="text-element"></div>
                        <p className="paragraph1">
                            {oa["What do We Do?"]}
                        </p>
                    </div>
                    <div className="base-div ">
                        <ul className="list">
                            <li className="list-item">
                                <div className="icon-holder">
                                    <img src={webDesign} alt="" />
                                </div>
                                <h5 className="header5">Website Design & Development</h5>
                                <p className="paragraph3">
                                    {oa["Website Design & Development"]}
                                </p>
                            </li>
                            <li className="list-item">
                                <div className="icon-holder">
                                    <img src={mobileApps} alt="" />
                                </div>
                                <h5 className="header5">iOS & Android Mobile Apps</h5>
                                <p className="paragraph3">
                                    {oa["iOS & Android Mobile Apps"]}
                                </p>
                            </li>
                            <li className="list-item">
                                <div className="icon-holder">
                                    <img src={customSoftwareSolutions} alt="" />
                                </div>
                                <h5 className="header5">Custom Software Solutions</h5>
                                <p className="paragraph3">
                                    {oa["Custom Software Solutions"]}
                                </p>
                            </li>
                        </ul>
                    </div>
                </section>
                <section className="image-representation-section">
                    <div className="head-base-div">
                        <h3 className="header3">How Traditional Parking Lots Works?</h3>
                    </div>
                    <div className="base-div">
                        <div className="image-holder">
                            <img src={offParkingHowWorks} alt="" />
                        </div>
                    </div>
                    <div className="head-base-div">
                        <h3 className="header3">Parking lots Powered by PazeTech</h3>
                        <p className="paragraph2">
                            With help of PazeTech Softwares, you can digitalise your entire parking lot opertaions from praking reservation, shuttle tracking, customer support & more!
                        </p>
                    </div>
                    <div className="base-div">
                        <div className="image-holder">
                            <img src={offParkingPazetech} alt="" />
                        </div>
                    </div>
                </section>
                <section className="why-section">
                    <div className="head-base-div">
                        <h2 className="header2">Why you should not ignore your competitors?</h2>
                        <p className="paragraph1">
                            {oa["Why you Shouldn’t Ignore your competitors"]}
                        </p>
                        <div className="text-element"></div>
                    </div>
                    <div className="base-div">
                        <div className="image-holder ">
                            <img src={yourCompetitors} alt="" />
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th className="header4">
                                        <span>Large Multi Location Lots Large Corporations Parking Spot PreFlight Parking Wally Parking</span>
                                    </th>
                                    <th className="header4">
                                        <span>Traditional Parking Lots Small Business Owner Private Lots</span>
                                    </th>
                                    <th className="header4">
                                        Powered by PazeTech
                                        <br></br>
                                        <br></br>
                                        <span>Traditional Parking Lots Small Business Owner Private Lots</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="paragraph3">Modern Responsive Website</td>
                                    <td>
                                        <img src={tickMark} alt="" />
                                    </td>
                                    <td>
                                        <img src={xMmark} alt="" />
                                    </td>
                                    <td>
                                        <img src={tickMark} alt="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="paragraph3">Online Reservation Engine</td>
                                    <td>
                                        <img src={tickMark} alt="" />
                                    </td>
                                    <td>
                                        <img src={xMmark} alt="" />
                                    </td>
                                    <td>
                                        <img src={tickMark} alt="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="paragraph3">Mobile Application</td>
                                    <td>
                                        <img src={tickMark} alt="" />
                                    </td>
                                    <td>
                                        <img src={xMmark} alt="" />
                                    </td>
                                    <td>
                                        <img src={tickMark} alt="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="paragraph3">Rewards Program</td>
                                    <td>
                                        <img src={tickMark} alt="" />
                                    </td>
                                    <td>
                                        <img src={xMmark} alt="" />
                                    </td>
                                    <td>
                                        <img src={tickMark} alt="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="paragraph3">Loyalty Program</td>
                                    <td>
                                        <img src={tickMark} alt="" />
                                    </td>
                                    <td>
                                        <img src={xMmark} alt="" />
                                    </td>
                                    <td>
                                        <img src={tickMark} alt="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="paragraph3">Email Automation</td>
                                    <td>
                                        <img src={tickMark} alt="" />
                                    </td>
                                    <td>
                                        <img src={xMmark} alt="" />
                                    </td>
                                    <td>
                                        <img src={tickMark} alt="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="paragraph3">Third Party Integration</td>
                                    <td>
                                        <img src={tickMark} alt="" />
                                    </td>
                                    <td>
                                        <img src={xMmark} alt="" />
                                    </td>
                                    <td>
                                        <img src={tickMark} alt="" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
                <section className="feature-section white-bg">
                    <div className="base-div">
                        <div className="content-holder">
                            <h6 className="header6">What owners / managers get?</h6>
                            <h2 className="header2">Parking Lot Automation</h2>
                            <div className="text-element"></div>
                            <p className="paragraph1">
                                {oa["Parking Lot Automation"]}
                            </p>
                            <h5 className="header5">Key Features</h5>
                            <ul className="list">
                                <li className="list-item">
                                    <div className="icon-holder">
                                        <img src={tickMark} alt="" />
                                    </div>
                                    <p className="paragraph3">World Class Reservation Engine</p>
                                </li>
                                <li className="list-item">
                                    <div className="icon-holder">
                                        <img src={tickMark} alt="" />
                                    </div>
                                    <p className="paragraph3">Customer Relationship Management</p>
                                </li>
                                <li className="list-item">
                                    <div className="icon-holder">
                                        <img src={tickMark} alt="" />
                                    </div>
                                    <p className="paragraph3">Shuttle Dispatch Management</p>
                                </li>
                                <li className="list-item">
                                    <div className="icon-holder">
                                        <img src={tickMark} alt="" />
                                    </div>
                                    <p className="paragraph3">Manage Online Reservation</p>
                                </li>
                                <li className="list-item">
                                    <div className="icon-holder">
                                        <img src={tickMark} alt="" />
                                    </div>
                                    <p className="paragraph3">Real Time Shuttle UpdateDispatcher View</p>
                                </li>
                                <li className="list-item">
                                    <div className="icon-holder">
                                        <img src={tickMark} alt="" />
                                    </div>
                                    <p className="paragraph3">Integrated VoIP</p>
                                </li>
                                <li className="list-item">
                                    <div className="icon-holder">
                                        <img src={tickMark} alt="" />
                                    </div>
                                    <p className="paragraph3">Multi-Channel Customer Chat Desk</p>
                                </li>
                                <li className="list-item">
                                    <div className="icon-holder">
                                        <img src={tickMark} alt="" />
                                    </div>
                                    <p className="paragraph3">Valet Management</p>
                                </li>
                            </ul>
                        </div>

                        <div className="image-holder">
                            <img src={parkingSofwares} alt="" />
                        </div>
                    </div>
                </section>
                <section className="multi-point-section">
                    <div className="head-base-div">
                        <h3 className="paragraph6">World Class Reservation Engine</h3>
                    </div>
                    <div className="base-div">
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconz} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">World Class Reservation Engine</h5>
                                <p className="paragraph3">{oa["World Class Reservation Engine"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconb} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Digitalize Parking Lot Operation</h5>
                                <p className="paragraph3">{oa["Digitalize parking lot operation"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconah} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Complete Customer / Order Analytics</h5>
                                <p className="paragraph3">{oa["Complete Customer / Order Analytics"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={icona} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Third-Party Integration</h5>
                                <p className="paragraph3">{oa["Third Party Integration"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconag} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Integration to Corporate Expense Management Tools</h5>
                                <p className="paragraph3">{oa["Integration to Corporate Business Expense Tools"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconap} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Diversify Business Revenue - Valet / Car Care</h5>
                                <p className="paragraph3">{oa["Diversify Business Revenue - Valet / Car Care"]}</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="multi-point-section">
                    <div className="head-base-div">
                        <h3 className="paragraph6">Customer Relationship Management</h3>
                    </div>
                    <div className="base-div">
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconai} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Email / SMS Marketing Automation</h5>
                                <p className="paragraph3">{oa["Email & SMS Automation"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconu} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Rewards & Discounts, Loyalty Management</h5>
                                <p className="paragraph3">{oa["Rewards & Discounts, Loyalty Management"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconad} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Gift Card Management</h5>
                                <p className="paragraph3">{oa["Gift Card Management"]}</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="feature-section color-bg">
                    <div className="base-div">
                        <div className="content-holder">
                            <h6 className="header6">What your customers get?</h6>
                            <h2 className="header2">Customer Facing App</h2>
                            <div className="text-element"></div>
                            <p className="paragraph1">
                                {oa["Customer Facing App"].header}
                            </p>
                            <h5 className="header5">Key Features</h5>
                            <ul className="list">
                                {oa["Customer Facing App"].items.map(i=>{
                                    return (
                                        <li className="list-item">
                                            <div className="icon-holder">
                                                <img src={tickMark} alt="" />
                                            </div>
                                            <p className="paragraph3">{i}</p>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        <div className="image-holder unset-padding">
                            <img src={parkingSofwares} alt="" />
                        </div>
                    </div>
                </section>
                <section className="multi-point-section color-bg">
                    <div className="head-base-div">
                        <h3 className="paragraph6">Parking Reservation Benefits</h3>
                    </div>
                    <div className="base-div">
                        {oa["Parking Reservation Benefits"].map(i=>{
                            return (
                                <div className="content-holder">
                                    <div className="icon-holder">
                                        <img src={i.icon} alt="" />
                                    </div>
                                    <div>
                                        <h5 className="header5">{i.title}</h5>
                                        <p className="paragraph3">{i.description}</p>
                                    </div>
                                </div>
                                )
                            })}
                    </div>
                </section>
                {/* <section className="client-section">
                    <div className="head-base-div">
                        <h2 className="header2">Our clients love what we do</h2>
                        <div className="text-element"></div>
                    </div>
                    <div className="base-div">
                        <div id="myCarousel" className="carousel slide carousel-fade salesCarousel" data-ride="carousel">
                            <div className="carousel-inner list" role="listbox">
                                <section className="item" id="slide-1">
                                    <div className="list-item">
                                        <div className="image-holder">
                                            <img src={blueskyLogo} alt="" />
                                        </div>
                                        <div className="content-holder">
                                            “Massa tincidunt nunc pulvinar sapien et ligula. Volutpat ac tincidunt vitae semper quis lectus nulla at volutpat. Mi eget mauris pharetra et ultrices
                                            neque”
                                        </div>
                                    </div>
                                </section>
                                <section className="item active" id="slide-1">
                                    <div className="list-item">
                                        <div className="image-holder">
                                            <img src={blueskyLogo} alt="" />
                                        </div>
                                        <div className="content-holder">
                                            “We are the leader in smart parking and mobility solutions. We connect you with our audience of over 15 million people looking for parking every single
                                            day.”
                                        </div>
                                    </div>
                                </section>
                                <section className="item" id="slide-1">
                                    <div className="list-item">
                                        <div className="image-holder">
                                            <img src={blueskyLogo} alt="" />
                                        </div>
                                        <div className="content-holder">
                                            “Massa tincidunt nunc pulvinar sapien et ligula. Volutpat ac tincidunt vitae semper quis lectus nulla at volutpat. Mi eget mauris pharetra et ultrices
                                            neque”
                                        </div>
                                    </div>
                                </section>
                                <section className="item" id="slide-1">
                                    <div className="list-item">
                                        <div className="image-holder">
                                            <img src={blueskyLogo} alt="" />
                                        </div>
                                        <div className="content-holder">
                                            “We are the leader in smart parking and mobility solutions. We connect you with our audience of over 15 million people looking for parking every single
                                            day.”
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <ol className="carousel-indicators">
                                <li data-target="#myCarousel" data-slide-to="0" className=""></li>
                                <li data-target="#myCarousel" data-slide-to="1" className="active"></li>
                                <li data-target="#myCarousel" data-slide-to="2" className=""></li>
                                <li data-target="#myCarousel" data-slide-to="3" className=""></li>
                            </ol>
                        </div>
                    </div>
                </section> */}
            </div>
        );
    }
}
