export let HomePage = {
    "Solution_for_Parking_&_Shuttling_Services": "Pazetech provides high-quality, customized solutions for parking operators, fleet services, off-site airport parking, and more. Top-notch software & automation services allow you to compete with large corporations in this competitive market",
    "Who_we_Are": "PazeTech provides industry best IT solutions for parking lot operators, airport ride share programs, valet services, fleet operators, and more. Our team of Product Manager and Technical experts  will work with you side by side to come up with custom requirements to help take your business to the next level. We’ve been helping our customers since 2011. Our proven systems are designed to help improve efficiency, reduce cost, and even expand into additional services to help boost profitability. Don’t get bogged down trying to develop your own technology solutions. Let us design, develop, deploy and sustain these advanced systems so you can continue to focus on what you do best.",
    "What_do_We_Do": "At the core, Pazetech is a technology company with a passion for developing high-quality solutions for the parking, fleet, offsite airport parking, airport ride share, and valet industry. We will serve as your IT Infrastructure and technology partner to manage and sustain your company's website, mobile app, custom software, and much more.",
    "Website_Design_&_Development":"In today’s modern economy, every business has to have a website. We’ll craft a responsive site that will help to draw in potential customers. On this platform visitors will be able to book reservations, process credit cards using sophisticated PCI compliant solutions, and more.",
    "iOS_&_Android_Mobile_Apps": "We’ll develop a custom branded mobile app that your customers can download to make interacting with your business faster and easier than ever before. Included in your app will be options to book services, make payments, and much more.",
    "Custom_Software_Solutions": "Our custom software solutions will help make managing your offsite parking, fleet management, and other services easier and more effective than ever. Our engineers will work with you to determine your exact needs and develop a software solution that will exceed your expectations.",
    "Solutions_We_Provide": "Automate your day-to-day operations using our proven, simplified, technology solutions. The customized dashboard console will allow you to operate your entire business from a single platform. Streamline your services, track your assets, and much more using our software solutions.",
    "Off_Airport_Parking": "Managing an off-airport parking service requires exceptional logistics to ensure everything goes smoothly. Customers will benefit from online reservation using the customer app or a website. They can also confirm, modify, or cancel reservations from this one convenient interface.",
    "Airport_Rideshare": "We’ll develop software to help you coordinate and optimize your airport rideshare business. Our team will work with you to determine the unique requirements that your customers have and find innovative software solutions.",
    "Valet_Parking": "Offer your customers an exclusive valet parking service to make their lives even easier. They can book a valet from your app, drive up to their terminal, and your driver will be there to bring the vehicle back to your lot. This is the ultimate convenience for your customers, and a great extra income option for you. Great for hotels, restaurants, airport parking services, and more.",
    "Fleet_Tracking": "Managing a fleet of cars, trucks, shuttles, and other vehicles can be a challenge. We will help to implement a system that will automatically track every vehicle in your fleet, so you know what is going on at all times. Avoid downtime, delays, and other common problems using this effective service.",
    "Pay_by_Phone": "Pay-by-phone parking is a rapidly growing and evolving industry with a lot of potential. Allowing customers to pay via the phone or app makes it faster and easier for everyone. Let us implement a pay-by-phone option to meet the needs of your customers.",
    "How_we_Work": "We meet with each client to discuss their unique needs and help develop effective solutions. This customized approach helps to ensure you are getting exactly what you need, for a price that is far more affordable than you might expect. Each new project we take on goes through three main steps:",
    "Provide_Your_Business_Requirements": "We’ll meet with you to gather the details of what you need. Our experienced team knows what questions to ask in order to ensure we understand exactly what it is that you’re looking for.",
    "Design_&_Development": "Our team of Technical Leaders and Product owners will come up with a customized design to accomplish your goals. We’ll then develop the system, perform extensive testing, and have it installed onto your computer systems.",
    "Product_Launch": "Once everything in place, it is time for the product launch. This is where we will publish your new website, release your mobile app, and much more. Of course, we’ll be there to support this system long after it has launched!",
    "What_Makes_Us_the_Best_Choice_for_Businesses": "Large parking corporations are spending millions to develop and implement advanced technological solutions for their business. PazeTech allows small to mid-sized businesses to compete using top of the line business management and automation solutions that are customized just for your use cases.",
    "Schedule_a_Demo": ""
}
export let OffsiteAirport = {
    "Off Site Airport Parking": "Pazetech's off-site parking automation system will enable you to create all new revenue streams for your business. Major parking lot companies are using advanced technology to effectively acquire new customers. Using Pazetech, you can protect and even expand your parking business. ",
    "What Problem Off Site Parking Lot Owners Faces?": { 
        header: "Air travel is more popular than ever, which means more opportunity for off-site parking lot owners. Despite this, however, there are significant problems and challenges that must also be faced:",
        items: [
            "Large Corporations Such As PreFlight, The Parking Spot, and Wally are Using In-house developed, highly sophisticated Reservation Systems, Rewards Programs, and More. ",
            "Ride Hailing Services Like Uber Eliminating the Need for Parking.",
            "Increased On-Site Airport Parking."
        ]
    },
    "How our Solution will over come those problems?": { 
        header: "Pazetech's system will help you to not just overcome these challenges, but thrive in the current business climate.",
        items: [
            "Scalable Technology with Low Costs Allow You to Compete Against Large Companies.", 
            "Our Team Works CloseLy with You to Customize Your Services.",
            "The PazeTech Software Solutions Run on Top of Cloud Technology to Ensure Everything is Up for Your Customers at All Times."
        ]
    },
    "What do We Do?": "With more than 7 years of experience working with a local off-site airport parking lot operators, we can take your business to the next level. Let us provide you with the software and automation solutions needed to compete with the major parking corporations in the industry. ",
    "Website Design & Development": "If you already have a website in place, we'll review it and make some recommendations on how to make it more effective than ever. If you don't have a website, we will create a custom design and have it developed for your business. ",
    "iOS & Android Mobile Apps": "Mobile apps are essential when serving today's tech-savvy customers. We will create a beautiful frontend app for your iOS & Android customers, as well as the back-end interface for you to manage your services.",
    "Custom Software Solutions": "Do you have unique services that help to set your off-site parking business apart? If so, we'll develop customized software solutions that will optimize your entire operation. ",
    //"How traditional parking lots Work?": "",
    //"Parking Lots Powered by PazeTech": "",
    "Why you Shouldn’t Ignore your competitors": "Off-site airport parking is more competitive than ever, with many large corporations moving into virtually all markets. They spend millions on their technology to serve their customers. PazeTech allows you to take advantage of high-tech solutions that will allow you to out-compete your competitors. ",
    "Parking Lot Automation": "Manage your operations from anywhere in the world thanks to our Insights App. Insights by PazeTech allows you to stay on top of your operations from the office, home, or even on the road. Take advantage of straight-forward, real-time, sales data, manage labor, and forecast future sales all from the convenience of your mobile device. ",
    "Advance Reservation System": "Let your clients quickly and easily reserve a parking spot using their phone or computer. Our easy to use interface allows customers to book their parking spot, include add-ons, and confirm reservations 24 hours a day.",
    "World Class Reservation Engine": "Save time and money by automating every reservation task including showing up-to-date availability, price quotes, requests for information, customer cancellations or modifications, and sending automatic confirmations. ",
    "Complete Customer / Order Analytics": "Details from every customer and every order can be analyzed to help grow and improve your business.",
    "Integration to Corporate Business Expense Tools": "Manage corporate business expenses using our advanced tools that are integrated into your platform.",
    "Digitalize parking lot operation": "Cut costs and overhead by digitalizing virtually every aspect of parking lot operations.",
    "Third Party Integration": "Leverage our robust network of third-party features to take your system to the next level or have us craft custom options to integrate into your platform.",
    "Diversify Business Revenue - Valet / Car Care": "Add new revenue streams by replicating your existing business model into services like Valet, Car care, and more.",
    //"Customer Relationship management": "",
    "Marketing Automation": "Gather customer information and automatically market to them regularly to keep them coming back. Our marketing automation suite is easy and effective. ",
    "Email & SMS Automation": "Engage with existing customers through opt-in email and SMS marketing features to build brand loyalty. ",
    "Gift Card Management": "Increase sales, retain customers, attract new customers, and more with our Customer Loyalty Module.",
    "Rewards & Discounts, Loyalty Management": "Create the right discounts for the right customers at the right times to maximize brand loyalty. Our robust discount options will reward your top customers and create new business!",
    "Customer Facing App": {
        header: "We are the leader in smart parking and mobility solutions.",
        items: [
            "Facility Information",
            "Additional Services Car Care or Valet Request",
            "Available on iOS and Android",
        ]
    },
    "Parking Reservation Benefits": [
        { icon: "image/icon-f.png", title: "Real Time Shuttle Tracking", description: "Keep your customers informed on when the next shuttle will arrive so they can plan accordingly." },
        { icon: "image/icon-m.png", title: "Car Locator", description: "Reminding customers where they were parked is a great way to welcome them back after a trip. It will also leave them with a positive experience from your business."},
        { icon: "image/icon-q.png", title: "Request Shuttle For Pick up", description: "Allow your customers to request a shuttle right from the convenience of their phone. "},
        { icon: "image/icon-u.png", title: "Profile Management (Rewards, Loyalty, Coupon Accounts)", description: "Building company loyalty is essential for long term success. We incorporate loyalty points, coupons, and more into your system. "},
        { icon: "image/icon-p.png", title: "Web & Mobile checkout", description: "Give your customers the flexibility to book online at their convenience. Generate more orders, improve customer loyalty, and increase revenue."},
        { icon: "image/icon-ac.png", title: "Chat w/ Customer Service", description: "Customers can get in touch with your customer service team with just a quick tape or click. "}
    ],
}
export let AirportShuttle = {
    "Digitize Airport Shuttle Operation": "Airport shuttles are an essential service that travelers need to take advantage of. Managing this service can be quite complex due to scheduling, driver management, and much more. Whether you operate a shuttle service to your offsite parking location, local hotels, home pickup, or all of these options, our software solutions are here for you.",
    "What Problem Airport Rdeshare Operator Faces?": {
        header: "The airport shuttle and rideshare industry has gotten increasingly competitive over the years due to advancements in technology, new competitors, and more. We understand the challenges that airport shuttle services face, and will help you to overcome them.",
        items: [
            "Competition from Uber and Related Services", 
            "Difficulties keeping Shuttles on Schedule", 
            "Problems Related to Driver Management", 
            "Tracking Shuttle Maintenance", 
            "Onsite Airport Parking",
            "Competitors Offering Rideshare Services" 
        ]
    },
    "How our Solution will over come those problems?": { 
        header: "Our team has helped Off-site airport parking and shuttle operators throughout the country. We offer custom solutions to all the problems you face, including:", 
        items: [
            "Effective Marketing to Attract New Customers", 
            "Managing Drivers for Optimum Availability",
            "Shuttle Tracking",
            "Shuttle Scheduling",
            "Vehicle Maintenance Planning",
            "Revenue Tracking",
            "Much More" 
        ]
    },
    "What do We Do?": "PazeTech creates customized software and automation tools to help companies in the Off-site airport parking and rideshare industries. Major corporations are investing millions into this type of software, and we let businesses of all sizes compete effectively.",
    "Website Design & Development": "We can create a custom website from the ground up, or help to redesign an existing site, to provide customers with an easy interface to your business. This branded website will allow for shuttle tracking, booking rides, and more.",
    "iOS & Android Mobile Apps": "You can have a branded mobile app that customers use to schedule their trips, get alerted when shuttles are on the way, and much more. They can also make reservations, manage payments, and even get in touch with your customer service team.",
    "Custom Software Solutions": "Our Technical Leaders and Product Managers will work closely with you to determine exactly what services you need, and make sure they are incorporated into your software suite. This can include third party integrations, custom modules, and more.",
    "Why Choose us for Airport Rideshare Automation": "PazeTech is the industry leader when it comes to airport shuttle automation, software development, and customer portal interfaces. We aren’t a ‘one size fits all’ software company, but instead work hard to provide you with exactly what you need at a price that is within your budget.",
    "How Airport Rideshare Business Works?": "We understand how the airport shuttle and rideshare business works from beginning to end. This will allow us to work with you to provide the custom solutions you need.",
    "Airport Rideshare Automation": { 
        header: "You can automate a large portion of your airport shuttle business to help improve efficiency, eliminate waste, and maximize profitability. We have helped many other businesses take their services to the next level, and we’re here to help you too.", 
        items: [
            "Web Reservation Management",
            "Customer Relationship Management",
            "Profile Management",
            "Create/Edit/Cancel Reservation",
            "Real-Time Shuttle Tracking",
            "Integrated Chat Between Agent and Dispatch",
            "Integrated Chat Between Agent and Customer"
        ]
    },
    "Management Module": { 
        header: "Our management console will allow you to manage virtually every aspect of your business from one central location. This means you can keep track of and run your business from the office, your home, or even while on vacation! You won’t find an easier way to manage your airport shuttle services.",
        items: [ 
            { icon: "image/icon-z.png", title: "Book, Update and Cancel Reservation", description: "Customers can create new reservations, edit them, or cancel them in real-time with just a few quick taps or clicks." },
            { icon: "image/icon-ao.png", title: "Make Reservation via Web, Mobile & Tablets", description: "The online reservation tool makes it fast and easy for customers to book the rides they need. This also helps to attract new customers who want this great convenience." },
            { icon: "image/icon-d.png", title: "Pre-Scheduled or On Demand Reservation", description: "Customers can either schedule a ride ahead of time, or use the on-demand reservation tools to get a ride as soon as possible." },
            { icon: "image/icon-an.png", title: "Printable Reservation Tickets", description: "The app will create a reservation ticket that can either be scanned right on the phone, or printed, based on the customer’s preference." },
            { icon: "image/icon-am.png", title: "Promotion Marketing Tools", description: "Create automated marketing campaigns to attract new customers, bring existing customers back, and upsell additional services." },
            { icon: "image/icon-ai.png", title: "Marketing Automation (Email / SMS)", description: "Your marketing campaigns can take advantage of effective email and SMS marketing tools." },
            { icon: "image/icon-ad.png", title: "Gift Card Management", description: "Allow your customers to purchase and redeem gift cards from the convenience of your mobile app or a web portal" },
        ]
    },
    "Driver Module": { 
        header: "The driver module within your app will allow your drivers to see new reservations in real time, track the schedule they need to keep, and much more. This driver module is an easy and effective way to manage drivers and keep them serving your customers properly.",
        items: [ 
            { icon: "image/icon-am.png", title: "Promotion Marketing Tools", description: "Create automated marketing campaigns to attract new customers, bring existing customers back, and upsell additional services." },
            { icon: "image/icon-ai.png", title: "Marketing Automation (Email / SMS)", description: "Your marketing campaigns can take advantage of effective email and SMS marketing tools." },
            { icon: "image/icon-ad.png", title: "Gift Card Management", description: "Allow your customers to purchase and redeem gift cards from the convenience of your mobile app or web portal" },
        ]
    },
    "Customer Module": { 
        header: "The customer module is a direct interface between your business and your customers. They can create reservations, make modifications, manage loyalty points, and even communicate with your customer service.",
        items: [ 
            { icon: "image/icon-d.png", title: "Pre-Scheduled or On Demand Reservation", description: "Customers can either schedule a ride ahead of time, or use the on-demand reservation tools to get a ride as soon as possible." },
            { icon: "image/icon-an.png", title: "Printable Reservation Tickets", description: "The app will create a reservation ticket that can either be scanned right on the phone, or printed, based on the customer’s preference." },
            { icon: "image/icon-am.png", title: "Promotion Marketing Tools", description: "Create automated marketing campaigns to attract new customers, bring existing customers back, and upsell additional services." },
            { icon: "image/icon-ai.png", title: "Marketing Automation (Email / SMS)", description: "Your marketing campaigns can take advantage of effective email and SMS marketing tools." },
            { icon: "image/icon-ad.png", title: "Gift Card Management", description: "Allow your customers to purchase and redeem gift cards from the convenience of your mobile app or web portal" },
        ]
    },
    "Customer Facing App": "When your customers download the customized mobile app on iOS or Android, they will be able to manage their entire shuttle experience from one location. This is a fast and easy way to ensure your customers are getting exactly what they want, while also building brand recognition and loyalty.",
    "Shuttle Reservation Benefits": [
        { icon: "image/icon-f.png", title: "Real Time Airport Rideshare Tracking", description: "Customers can track the location of their shuttle in real time to ensure they are ready for pickup when it arrives." },
        { icon: "image/icon-r.png", title: "Real Time Status Notifications", description: "Your customers can opt to receive a notification on their mobile device when their shuttle is approaching." },
        { icon: "image/icon-q.png", title: "Request Airport Rideshare for Pick Up", description: "Create a request for an airport rideshare or shuttle pickup with just a tap." },
        { icon: "image/icon-u.png", title: "Rewards & Loyalty Account", description: "Customers can view the rewards or loyalty points they have earned, and redeem them, right on the app." },
        { icon: "image/icon-p.png", title: "Web & Mobile checkout", description: "The web or mobile checkout feature allows customers to make payments right from their phone." },
        { icon: "image/icon-ac.png", title: "Chat w/ Customer Service", description: "Customers can reach out to your customer service team right from the app to get the support they need." }
   ]
}
export let ValetParking = {
    "Valet Parking": "Valet parking is an important part of many businesses. Whether it is a free service offered to customers, or an additional revenue stream, our software solutions will help to streamline your operations. We will create a customized valet parking management console to maximize efficiencies and customer services.",
    "What Problem Valet parking Operator faces?": { 
        header: "Valet parking is a great service, but it can be difficult to manage. We understand the challenges that valet providers face, and will help you to overcome them:",
        items: [
            "Having Drivers Available when Customers Pull Up",
            "Retrieving the Right Vehicle",
            "Problems with Vehicles After Valet",
            "Locating Parking Location of Each Vehicle",
            "Many Others" 
        ]
    },
    "How our Solution will over come those problems?": { 
        header: "Our advanced software suite is designed to help solve all of these common problems, and many others. Using PazeTech, we’ll help to streamline your entire valet parking business and overcome your challenges:",
        items: [
            "Efficiently Managing Drivers for Maximum Availability",
            "Automatic Linking of Vehicles with Owners", 
            "Tracking Which Driver Parked Each Vehicle",
            "Keeping Track of the Parking location of Each Vehicle",
            "Much More"
        ]
    },
    "What do We Do?": "PazeTech offers a proven software system that will allow you to more effectively and efficiently manage your valet parking services. This includes customized software, mobile apps, web interface, and much more to maximize profitability.",
    "Website Design & Development": "The web frontend of your valet parking services makes it fast and easy to check in vehicles, track their parking locations, schedule retrieval, and much more. This branded site will be customized with the exact features you need.",
    "iOS & Android Mobile Apps": "Take advantage of mobile apps for your customers as well as your drivers. These apps will allow for convenient scheduling, vehicle tracking, and much more.",
    "Custom Software Solutions": "If you have unique services or options you want included in your software suite, our engineers will work closely with you to develop and incorporate it into your order.",
    "How Valet Parking process works?": "We make the entire valet parking process easy and convenient for customers as well as drivers. This will help to bring in new customers, and keep existing ones coming back regularly.",
    "Scan a ticket": "Customers scan their ticket to prepare to drop off their vehicle with a valet. This begins the registration process for their car.",
    "Provide vehical details and mobile number": "The vehicle details are filled out, along with the mobile number of the customer.",
    "Drop your car to valet operator": "When ready, the customer will drop the vehicle off to the valet operator, who will be ready to safely park it right away.",
    "Send pick up request for Pick up": "When the customer is ready to retrieve their vehicle, they send a pickup request via the website or mobile app. This will ensure the valet operator has the vehicle ready to go when the customer arrives.",
    "Pay via Mobile App": "Payment is made via the mobile app for ultimate in convenience.",
    "Valet Operator Automation": {
        header: "Valet operators will benefit from this system, which will allow them to serve customers better and improve the efficiency of your business. Our valet operator automation solutions provide a number of important features. Key Features:",
        items: [ 
            "Managing Locations of Vehicles",
            "Assigning Valet",
            "Requesting Vehicle ",
            "Managing Multiple Drivers",
            "No Need for Operators to Manage Payments"
        ] 
    },
    //"Valet Automation Benefits": "",
    "Manage Multiple Drivers": "Ensure drivers are assigned to vehicles appropriately and track which vehicles they drive.",
    "Manage reservation /orders": "Customers can make reservations ahead of time, which helps with planning and efficiency.",
    "Revenue Management": "Track the revenue based on time of day, day of the week, special events, and much more.",
    "Send Status and order details to customers": "Keep customers informed of the status of their valet, and send them order details, to ensure appropriate communication.",
    //"Customer Relationship Management": "",
    "Marketing Automation": "Attract new customers and keep existing customers coming back with automated email and SMS marketing campaigns.",
    "Gift Card Management": "Manage gift cards to make it easier to buy and use for the customers, and accept payment through the web portal or mobile app.",
    "Rewards & Loyalty Management": "Create an effective customer loyalty system that is automatically managed to help keep customers coming back.",
    "Customer Web App": "The customer web app is intuitive and easy to use. They will be able to manage payments, schedule valet pickup, and much more. In addition, this will help to build brand recognition and customer loyalty.",
    "Valet Parking Benefits": "Valet parking is a great service to offer to your customers. Whether it is a free park to attract business, or an additional revenue opportunity, customers will love it and you will too.",
    "One touch car request":"",
    "Request Car Pick up to Valet Parking": "Your customers can request a pickup before heading to the valet station to avoid delays. This allows you to have their vehicle ready to go as soon as it is needed.",
    "Rewards & Loyalty Management2": "Customers can track earned loyalty points, choose when to redeem them, and much more.",
    "Chat w/ Customer Service": "If your customers have any questions or problems, they can get in touch with your customer service team right away through your mobile app.",
}
export let FleetTracking = {
    "Fleet Tracking": "Track every vehicle in your fleet to ensure they are on track and completing tasks appropriately. Our GPS system allows you to monitor the location of each vehicle, their speed, mileage, and more. This can be used for shuttle busses, trucks, work vehicles, and other situations where you want to monitor the activities of your drivers.",
    "What issues Fleet Tracking Faces?": {
        header: "Businesses that operate vehicles have a number of unique challenges that just aren’t found in other industries. We understand the issues that you face, including the following:",
        items: [
            "Keeping Vehicles on Schedule",
            "Drivers Taking Unapproved Routes",
            "Unsafe Driving Practices",
            "Taking Excessive Breaks",
            "Disputes Regarding Pickup or Dropoff Time",
            "Many More"
        ]
    },
    "How our Solution will over come those problems?": { 
        header: "Our advanced fleet tracking software will provide you with the information and insights needed to effectively overcome virtually every challenge you face.",
        items: [
            "Know the Exact Location of Every Vehicle in Real Time",
            "Monitor Routes Taken by All Vehicles",
            "Automatically Track Speeds and Other Driving Conditions",
            "Get Notified when Vehicles Aren’t in Use",
            "Proof of Dropoff/Pickup Times",
            "Much More" 
        ]
    },
    "What do We Do?": "PazeTech offers advanced solutions for all types of fleet tracking. We will work with you to understand your unique needs, and install a system that will work for you. This will include GPS tracking, custom web interfaces, mobile apps, and more.",
    "Website Design & Development": "Your customized web interface will allow you to track the location and activities of every vehicle in your fleet. Track all vehicles at once, or watch one driver to discover potential problems.",
    "iOS & Android Mobile Apps": "You can track your fleet from anywhere in the world with our convenient mobile apps. These apps are available for both iOS and Android devices, and will provide a powerful interface for you to keep an eye on your business at all times.",
    "Custom Software Solutions": "The logistics behind fleet tracking can be complicated and multifaceted. Our developers will work with you to learn about your unique needs, and create the software and systems needed to get you the best possible results.",
    "Why Choose us for Fleet Tracking": "We are a leader in the fleet tracking software solutions industry. No matter the size of your fleet, we’ll provide you with accurate tracking systems to give you the information needed to successfully run your business.",
    "Fleet Tracking Automation": [
        "Whether you run a trucking business, shipping services, taxis, shuttle busses, or any other business with a fleet of vehicles, we are here to help. Our automated software solutions will provide you with all the information you need about your vehicles, 24/7. This will help ensure your fleet is operating as efficiently and effectively as possible.",
        "Major fleet corporations invest millions of dollars into tracking and automation tools. With PazeTech, you can enjoy the benefits of advanced systems at a fraction of the cost."
    ],
    //"Fleet Automation Benefits": "",
    "Simplify Operation": "Track the location and performance of every vehicle in your fleet from one centralized interface for the easiest operation of your business.",
    "Fleet Performance": "Monitor the performance of your fleet as a whole, and by each vehicle, so you can optimize your services.",
    "Protect Your Assets From Theft": "Track stolen vehicles to assist police in recovery to help minimize the risk of theft related losses.",
    "Share Live Tracking with Customers": "Provide your customers with live tracking information for deliveries, pickups, ride sharing, and more.",
    "Manage Maintenance": "Manage the maintenance of your vehicles based on the specific miles, driving conditions, and other factors.",
    "Sercure Chat Support between Driver & Dispatcher": "Easy communication between your drivers and dispatchers to avoid miscommunications or other problems.",
    "Share Vehical Location between Drivers": "Inform drivers of vehicle locations to avoid overlapping services or other unwanted inefficiencies.",
    "3rd party Integration": "Integrate third party features into your fleet tracking solutions to get the most benefits from your system."
}
export let PayByPhone = {
    "Pay by Phone": "Paying for metered parking by phone is a rapidly growing service used. It allows customers to pay for their parking right through a convenient mobile app or site without having to use change or run a credit card. ",
    "What issues Meter Parking Faces?": {
        header: "Traditional metered parking is obsolete and inefficient. Businesses that manage metered parking face a variety of challenges that need to be overcome, including:",
        items: [ 
            "Monitoring Unpaid Usage of Parking Spaces",
            "Collecting Change from Meters",
            "Keeping Meters in Good Working Order",
            "Making the Service Easy to Use for Customers",
            "Attracting Customers During Non-Peak Hours",
            "Tracking Usage by Date and Time"
        ]
    },
    "How our Solution will over come those problems?": { 
        header: "Our Pay by Phone service solves common problems using advanced technology that your customers will love. The PazeTech pay by phone services benefit your business in many ways, including:",
        items: [
            "Easy to Use Mobile App Helps Customers find Open Parking Spots",
            "Credit card processing is built right in",
            "Automatic creation of usage reports for business tracking",
            "Customers pay only for the time they use the parking space",
            "No need to pick up cash from meters"
        ]
    },
    "What we do?": "When you sign up for our pay by phone services we will partner with you to craft a customized solution based on our powerful software. This will include a branded website, mobile app, and support to ensure everything is to your satisfaction.",
    "Website Design & Development": "Consult with our team to have an existing website modernized to meet the needs of customers wanting to pay by phone. If you don’t already have a website in place, we’ll develop one from the ground up.",
    "iOS & Android Mobile Apps": "Our mobile apps are powerful and easy to use. They not only allow customers to conveniently pay by phone, but will also help them to find the best parking spots so you can maximize profits.",
    "Custom Software Solutions": "If you have additional services or features that you want to offer, we can create customized software solutions. Chat with your dedicated account specialist to get exactly what you need.",
    //"How it works?": "",
    "Park Now": "With the help of PazeTech software you can allow your customers to pay for parking by phone. This helps to streamline the process, reduces expenses, and increases profits for the entire operation.",
    "Park Your Car": "Drive to Parking Spot and Park the Car",
    "Enter the Zone Number": "Enter the Zone or Meter Number",
    "Pay By phone": "Easily Pay by Phone for Hassle Free Payment",
    "Reserve for Later": "Customers will also have the option to reserve parking spots for later use, if you choose to offer that feature. Customers can find open spots, and check in early to ensure they are free when needed.",
    "Search for Spots": "Customers Use the Mobile App to Find Parking Spots in Advance",
    "Select Lots": "They Choose the Lot They Desire",
    "Make Reservation": "Confirm their Reservation with Just a Tap or Click",
    "Meter Parking Automation": [
        "PazeTech is the leader in smart parking solutions. We can help you to transform your metered parking services to be more convenient for your customers, and more profitable for you.", 
        "Our proven pay by phone solutions will serve as the platform on which your branded website and mobile app are built so that your customers have the best overall level of service possible."
    ],
    "Flexible Rate Management": "You choose the parking rates by location, date, time, or special events, all from an easy to use interface.",
    "Coupon Management": "Attract new customers and retain existing ones with an advanced coupon management system.",
    "Black out dates": "Manage black out dates with just a tap on your parking management system.",
    "Revenu Management": "Track and manage all your revenue systems to give you unbeatable control into your parking business.",
    "Complete Order Analytics": "Analyze every order to discover how to attract the most business today, and in the future.",
    "Customer Relationship Management": "Automatically build a relationship with each customer using rewards programs, coupons, digital marketing, and more.",
    // extra nodes in website: 
    //    Website Backend Mgmt for Lot Owners / Managers
    //    PCI Compliance that securely processes customer credit card right through your website
    "Customer Facing Application": {
        header: "The customer facing application will look and function beautifully. With a proven platform that is branded for your users, your customers will have access to all key features they need.",
        items: [
            "Easily Pay for Parking by Phone",
            "Reminders of Where They are ",
            "Choose from Multiple Payment Methods",
            "Take Advantage of Coupons & Discounts"
        ]
    },
    "Parking Enforcement": "Make sure you’re getting paid for every parking spot with automatic parking enforcement solutions.",
    "Advance Reservation System": "Reserve spots ahead of time to avoid any delays and get to their destination on time.",
    "Extend your parking": "Customers can extend their parking times to avoid penalties without having to return to their vehicle.",
    "Web / Mobile Checkout": "The entire checkout and payment process is done right from the mobile app or web interface for ultimate convenience.",
    "Rewards & Loyalty Management": "Customers can receive and use rewards and other loyalty perks from their app to make it easy to enjoy.",
    "Refer a Friend": "Your customers can refer friends to your system to enjoy great perks while bringing you new business!",
    "Coupoon & Discount Management": "Offer coupons or other discount options to new or existing customers to help grow your market."
}
