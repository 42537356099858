import React from "react";

export default class AboutUs extends React.Component {
    render() {
        let blueskyLogo = "image/bluesky-logo.png";
        let pazetechServices = "image/pazetech-services.png";
        let profile_image = "image/improve-customer-service.png";

        return (
            <div className="aboutus-section">
                <section className="hero-section">
                    <div className="bg-div"></div>
                    <div className="base-div ">
                        <span>
                            <h1 className="header2">About Us</h1>
                            <div className="text-element"></div>
                            <p className="paragraph1">We’re here to help and answer any question you might have. We look forward to hearing from </p>
                        </span>
                    </div>
                </section>
                <section className="who-section">
                    <div className="base-div ">
                        <div className="section-content">
                            <h2 className="header2">Who we are?</h2>
                            <div className="text-element"></div>
                            <p className="paragraph1">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Augue lacus viverra vitae congue eu
                                consequat ac felis donec. Nullam eget felis eget nunc lobortis.
                                <br></br>
                                <br></br>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Augue lacus viverra vitae congue eu
                                consequat ac felis donec. Nullam eget felis eget nunc lobortis.
                            </p>
                        </div>
                        <div className="section-image">
                            <img src={pazetechServices} alt="" />
                        </div>
                    </div>
                </section>
                <section className="ourvalue-section">
                    <div className="head-base-div ">
                        <h2 className="header2">Our Values</h2>
                        <div className="text-element"></div>
                    </div>
                    <div className="base-div">
                        <div className="content-holder">
                            <h5 className="header5">Our Mission</h5>
                            <p className="paragraph1">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Augue lacus viverra vitae congue eu
                                consequat ac felis donec. Nullam eget felis eget nunc lobortis.
                            </p>
                        </div>
                        <div className="content-holder">
                            <h5 className="header5">Our Vision</h5>
                            <p className="paragraph1">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Augue lacus viverra vitae congue eu
                                consequat ac felis donec. Nullam eget felis eget nunc lobortis.
                            </p>
                        </div>
                        <div className="content-holder">
                            <h5 className="header5">Our Goal</h5>
                            <p className="paragraph1">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Augue lacus viverra vitae congue eu
                                consequat ac felis donec. Nullam eget felis eget nunc lobortis.
                            </p>
                        </div>
                    </div>
                </section>
                <section className="team-section multi-white-bg">
                    <div className="head-base-div ">
                        <h2 className="header2">Meet the team</h2>
                        <div className="text-element"></div>
                    </div>
                    <div className="base-div">
                        <div className="team-holder">
                            <div className="image-holder">
                                <img src={profile_image} alt="" />
                            </div>
                            <div className="content-holder">
                                <h5 className="header5">Ninad.K.Patel</h5>
                                <p className="paragraph2">Founder of PazeTech</p>
                                <p className="paragraph1">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Augue lacus viverra vitae congue 
                                </p>
                            </div>
                        </div>
                        <div className="team-holder">
                            <div className="image-holder">
                                <img src={profile_image} alt="" />
                            </div>
                            <div className="content-holder">
                                <h5 className="header5">Howard Goodman</h5>
                                <p className="paragraph2">Co-Founder of PazeTech</p>
                                <p className="paragraph1">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="client-section multi-color-bg">
                    <div className="head-base-div">
                        <h2 className="header2">Our clients love what we do</h2>
                        <div className="text-element"></div>
                    </div>
                    <div className="base-div">
                        <div id="myCarousel" className="carousel slide carousel-fade salesCarousel" data-ride="carousel">
                            <div className="carousel-inner list" role="listbox">
                                <section className="item" id="slide-1">
                                    <div className="list-item">
                                        <div className="image-holder">
                                            <img src={blueskyLogo} alt="" />
                                        </div>
                                        <div className="content-holder">
                                            “Massa tincidunt nunc pulvinar sapien et ligula. Volutpat ac tincidunt vitae semper quis lectus nulla at volutpat. Mi eget mauris pharetra et ultrices
                                            neque”
                                        </div>
                                    </div>
                                </section>
                                <section className="item active" id="slide-1">
                                    <div className="list-item">
                                        <div className="image-holder">
                                            <img src={blueskyLogo} alt="" />
                                        </div>
                                        <div className="content-holder">
                                            “We are the leader in smart parking and mobility solutions. We connect you with our audience of over 15 million people looking for parking every single
                                            day.”
                                        </div>
                                    </div>
                                </section>
                                <section className="item" id="slide-1">
                                    <div className="list-item">
                                        <div className="image-holder">
                                            <img src={blueskyLogo} alt="" />
                                        </div>
                                        <div className="content-holder">
                                            “Massa tincidunt nunc pulvinar sapien et ligula. Volutpat ac tincidunt vitae semper quis lectus nulla at volutpat. Mi eget mauris pharetra et ultrices
                                            neque”
                                        </div>
                                    </div>
                                </section>
                                <section className="item" id="slide-1">
                                    <div className="list-item">
                                        <div className="image-holder">
                                            <img src={blueskyLogo} alt="" />
                                        </div>
                                        <div className="content-holder">
                                            “We are the leader in smart parking and mobility solutions. We connect you with our audience of over 15 million people looking for parking every single
                                            day.”
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <ol className="carousel-indicators">
                                <li data-target="#myCarousel" data-slide-to="0" className=""></li>
                                <li data-target="#myCarousel" data-slide-to="1" className="active"></li>
                                <li data-target="#myCarousel" data-slide-to="2" className=""></li>
                                <li data-target="#myCarousel" data-slide-to="3" className=""></li>
                            </ol>
                        </div>
                    </div>
                </section> */}
            </div>
        );
    }
}
