import React from "react";

export default class Footer extends React.Component {
    constructor(props) {
        super(props);

        this.onClickHandler = this.onClickHandler.bind(this);
    }

    onClickHandler = currentScreen => {
        this.props.onchange(currentScreen);
    };

    render() {
        let fbLogo = "image/fb-logo.png";
        let twitterLogo = "image/twitter-logo.png";
        let linkedinLogo = "image/linkedin-logo.png";
        let logo = "image/pazetech-color-logo.png";

        return (
            <footer className="footer">
                <div className="base-div">
                    <ul className="list full-width">
                        <li className="logo-holder">
                            <img onClick={() => this.onClickHandler("index")} src={logo} alt="" />
                        </li>
                        <li className="paragraph1 hide-xs">Because Parking Is Just a Starting Point.</li>
                        <li className="label2 hide-xs">
                            Copyrights © pazetech.com 2022 <br></br>
                            All Rights Reserved.
                        </li>
                    </ul>
                    <ul className="list">
                        <li className="paragraph4">SOLUTIONS</li>
                        <li className="paragraph5" onClick={() => this.onClickHandler("airportparking")}>
                            Off Airport Parking
                        </li>
                        <li className="paragraph5" onClick={() => this.onClickHandler("airportshuttle")}>
                            Airport Rideshare
                        </li>
                        <li className="paragraph5" onClick={() => this.onClickHandler("fleettracking")}>
                            Fleet Tracking
                        </li>
                        <li className="paragraph5" onClick={() => this.onClickHandler("valetparking")}>
                            Valet Parking
                        </li>
                        <li className="paragraph5" onClick={() => this.onClickHandler("paybyphone")}>
                            Pay by Phone
                        </li>
                    </ul>
                    {/* <ul className="list">
                        <li className="paragraph4">PRODUCTS</li>
                        <li className="paragraph5">Shuttle Tracker</li>
                        <li className="paragraph5">Valet Module</li>
                        <li className="paragraph5">Parking Reservation</li>
                        <li className="paragraph5">Parking Management</li>
                        <li className="paragraph5">ValetParking App</li>
                    </ul> */}
                    {/* <ul className="list hide-xs">
                        <li className="paragraph4">COMPANY</li>
                        <li className="paragraph5">About Us </li>
                        <li className="paragraph5">Media </li>
                        <li className="paragraph5">Blog </li>
                        <li className="paragraph5">Terms & Conditions </li>
                        <li className="paragraph5">Privacy </li>
                    </ul> */}
                    <ul className="list">
                        <li className="paragraph4 hide-xs" onClick={() => this.onClickHandler("contactus")}>
                            CONTACT US
                        </li>
                        <li className="paragraph5 hide-xs" >Feel free to get in touch with us via email</li>
                        <li className="label1 hide-xs">ninad.p@pazetech.com</li>
                        <li className="icon-holder">
                            <img src={fbLogo} alt="" />
                            <img src={twitterLogo} alt="" />
                            <img src={linkedinLogo} alt="" />
                        </li>
                        <li className="label2 hide-md">
                            Copyrights © pazetech.com 2022 <br></br>
                            All Rights Reserved.
                        </li>
                    </ul>
                </div>
            </footer>
        );
    }
}
