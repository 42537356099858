import React from "react";

export default class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showdropdown: true,
        };

        this.onClickHandler = this.onClickHandler.bind(this);
        this.showDropdown = this.showDropdown.bind(this);
    }

    onClickHandler = currentScreen => {
        this.showDropdown(currentScreen);
        this.props.onchange(currentScreen);
    };

    showDropdown = type => {
        let dropdownList = document.getElementsByClassName("navbar-collapse")[0];
        dropdownList.classList.remove("in");
    };

    render() {
        let logo = "image/pazetech-color-logo.png";
        let strCurrentScreen = this.props.activescreen;
        let boolIndex = strCurrentScreen === "index" ? true : false;
        let boolAboutus = strCurrentScreen === "aboutus" ? true : false;
        let boolSolution =
            strCurrentScreen === "airportparking" ||
            strCurrentScreen === "airportshuttle" ||
            strCurrentScreen === "valetparking" ||
            strCurrentScreen === "fleettracking" ||
            strCurrentScreen === "paybyphone"
                ? true
                : false;
        let boolPricing = strCurrentScreen === "pricing" ? true : false;

        return (
            <header className="header">
                <div className="base-div">
                    <nav className="navbar navbar-default" role="navigation">
                        <div className="navbar-header">
                            <button className="navbar-toggle" type="button" data-target="#navbarCollapse" data-toggle="collapse" onClick={() => this.showDropdown("show")}>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                            <p>
                                <img className="mainLogo" onClick={() => this.onClickHandler("index")} src={logo} alt="Pitch Deck" />
                            </p>
                        </div>
                        <div className="collapse login navbar-collapse" id="navbarCollapse">
                            <ul className="nav navbar-nav" id="navbar-nav">
                                <li>
                                    <p onClick={() => this.onClickHandler("index")} className="paragraph4" data-active={boolIndex}>
                                        Home
                                    </p>
                                </li>
                                <li>
                                    <div className="dropdown">
                                        <button className="dropdown-toggle paragraph4" type="button" data-toggle="dropdown" data-active={boolSolution}>
                                            Solutions
                                            <span className="caret"></span>
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li className="arrow"></li>
                                            <li className="paragraph5">
                                                <p onClick={() => this.onClickHandler("airportparking")}>Off Airport Parking</p>
                                            </li>
                                            <li className="paragraph5">
                                                <p onClick={() => this.onClickHandler("airportshuttle")}>Airport Rideshare</p>
                                            </li>
                                            <li className="paragraph5">
                                                <p onClick={() => this.onClickHandler("valetparking")}>Valet Parking</p>
                                            </li>
                                            <li className="paragraph5">
                                                <p onClick={() => this.onClickHandler("fleettracking")}>Fleet Tracking</p>
                                            </li>
                                            <li className="paragraph5">
                                                <p onClick={() => this.onClickHandler("paybyphone")}>Pay by Phone</p>
                                            </li>
                                        </ul>
                                    </div>
                                </li>

                                <li>
                                    <p className="paragraph4" onClick={() => this.onClickHandler("pricing")} data-active={boolPricing}>
                                        Pricing
                                    </p>
                                </li>
                                {/* <li>
                                    <p onClick={() => this.onClickHandler("aboutus")} className="paragraph4" data-active={boolAboutus}>
                                        About Us
                                    </p>
                                </li> */}

                                <li>
                                    <p>
                                        <button className="primary-button" onClick={() => this.onClickHandler("contactus")}>
                                            SCHEDULE A DEMO
                                        </button>
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        );
    }
}
