import React from "react";
import { FleetTracking as ft } from "./Content"

export default class FleetTracking extends React.Component {
    render() {
        let heroImage1x = "image/fleet-tracking-cover.png";
        let heroImage2x = "image/fleet-tracking-cover@2x.png";
        let webDesign = "image/web-design.png";
        let mobileApps = "image/mobile-apps.png";
        let customSoftwareSolutions = "image/custom-software-solutions.png";
        let parkingSofwares = "image/parking-sofwares.png";
        let fleetTrackingHowWorks = "image/fleet-tracking-how-it-works.png";

        let iconab = "image/icon-ab.png";
        let iconah = "image/icon-ah.png";
        let icono = "image/icon-o.png";
        let iconw = "image/icon-w.png";
        let icons = "image/icon-s.png";
        let iconap = "image/icon-ap.png";
        let iconm = "image/icon-m.png";
        let icona = "image/icon-a.png";

        return (
            <div className="fleet-tracking-section">
                <section className="hero-section">
                    <div className="hero-image">
                        <img className="hide-md" src={heroImage1x} alt="" />
                        <img className="hide-xs" src={heroImage2x} alt="" />
                    </div>
                    <div className="base-div ">
                        <span>
                            <h1 className="header1">Fleet Tracking</h1>
                            <p className="paragraph1">
                                {ft["Fleet Tracking"]}
                            </p>
                        </span>
                    </div>
                </section>
                <section className="problem-solution-section">
                    <div className="base-div">
                        <section className="problem-section">
                            <h3 className="header3">What issues Fleet tracking Faces?</h3>
                            <p className="paragraph1">{ft["What issues Fleet Tracking Faces?"].header}</p>
                            <ul className="list">
                                {ft["What issues Fleet Tracking Faces?"].items.map(i=>{
                                    return (
                                    <li className="list-item paragraph1">{i}</li>
                                    )
                                })}                                
                            </ul>
                        </section>
                        <section className="solution-section">
                            <h3 className="header3">How our solutions will overcome those problems?</h3>
                            <p className="paragraph1">{ft["How our Solution will over come those problems?"].header}</p>
                            <ul className="list">
                                {ft["How our Solution will over come those problems?"].items.map(i=>{
                                    return (
                                    <li className="list-item paragraph1">{i}</li>
                                    )
                                })}
                            </ul>
                        </section>
                    </div>
                </section>
                <section className="what-section">
                    <div className="head-base-div ">
                        <h2 className="header2">What we do?</h2>
                        <div className="text-element"></div>
                        <p className="paragraph1">
                            {ft["What do We Do?"]}
                        </p>
                    </div>

                    <div className="base-div ">
                        <ul className="list">
                            <li className="list-item">
                                <div className="icon-holder">
                                    <img src={webDesign} alt="" />
                                </div>
                                <h5 className="header5">Website Design & Development</h5>
                                <p className="paragraph3">
                                    {ft["Website Design & Development"]}
                                </p>
                            </li>
                            <li className="list-item">
                                <div className="icon-holder">
                                    <img src={mobileApps} alt="" />
                                </div>
                                <h5 className="header5">iOS & Android Mobile Apps</h5>
                                <p className="paragraph3">
                                    {ft["iOS & Android Mobile Apps"]}
                                </p>
                            </li>
                            <li className="list-item">
                                <div className="icon-holder">
                                    <img src={customSoftwareSolutions} alt="" />
                                </div>
                                <h5 className="header5">Custom Software Solutions</h5>
                                <p className="paragraph3">
                                    {ft["Custom Software Solutions"]}
                                </p>
                            </li>
                        </ul>
                    </div>
                </section>
                <section className="why-section white-bg">
                    <div className="head-base-div">
                        <h2 className="header2">Why Choose us for Fleet Tracking</h2>
                        <div className="text-element"></div>
                        <p className="paragraph1">
                            {ft["Why Choose us for Fleet Tracking"]}
                        </p>
                    </div>
                    <div className="base-div">
                        <div className="image-holder ">
                            <img src={fleetTrackingHowWorks} alt="" />
                        </div>
                    </div>
                </section>
                <section className="feature-section white-bg">
                    <div className="base-div">
                        <div className="content-holder">
                            <h6 className="header6">What owners / managers get?</h6>
                            <h2 className="header2">Fleet Tracking Automation</h2>
                            <div className="text-element"></div>
                            {ft["Fleet Tracking Automation"].map(i=>{
                                return (
                                    <p className="paragraph1">
                                        {i}
                                    </p> 
                                )
                            })}                            
                        </div>

                        <div className="image-holder">
                            <img src={parkingSofwares} alt="" />
                        </div>
                    </div>
                </section>
                <section className="multi-point-section">
                    <div className="head-base-div">
                        <h3 className="paragraph6">Automation Benefits</h3>
                    </div>
                    <div className="base-div">
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconap} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Simplify Operations</h5>
                                <p className="paragraph3">{ft["Simplify Operation"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconah} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Fleet Performance</h5>
                                <p className="paragraph3">{ft["Fleet Performance"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconab} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Protect Your Assets from Theft</h5>
                                <p className="paragraph3">{ft["Protect Your Assets From Theft"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={icono} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Share Live Tracking with Customers</h5>
                                <p className="paragraph3">{ft["Share Live Tracking with Customers"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconw} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Manage Maintenance</h5>
                                <p className="paragraph3">{ft["Manage Maintenance"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={icons} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Secure Chat Support between Driver and Dispatcher</h5>
                                <p className="paragraph3">{ft["Sercure Chat Support between Driver & Dispatcher"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconm} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Share Vehicle Location between Drivers</h5>
                                <p className="paragraph3">{ft["Share Vehical Location between Drivers"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={icona} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">3rd Party Integration</h5>
                                <p className="paragraph3">{ft["3rd party Integration"]}</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
