import React from "react";

export default class Pricing extends React.Component {
    render() {
        let benefitsSaasModel = "image/benefits-saas-model.png";
        let iconc = "image/icon-c.png";
        let iconal = "image/icon-al.png";

        return (
            <div className="pricing-section">
                <section className="hero-section">
                    <div className="bg-div"></div>
                    <div className="base-div ">
                        <span>
                            <h1 className="header2">Pricing</h1>
                            <div className="text-element"></div>
                            <p className="paragraph1">We’re here to help and answer any question you might have. We look forward to hearing from </p>
                        </span>
                    </div>
                </section>
                <section className="benefit-section">
                    <div className="head-base-div">
                        <h2 className="header2">Benefits of SaaS Model</h2>
                        <div className="text-element"></div>
                        {/* <p className="paragraph1">PazeTech helps millions of people easily find and pay for parking on their mobile devices.</p> */}
                    </div>
                    <div className="base-div">
                        <div className="image-holder">
                            <img src={benefitsSaasModel} alt="" />
                        </div>
                    </div>
                </section>

                <section className="pricing-model-section">
                    <div className="head-base-div">
                        <h2 className="header2">Development & Pricing Model</h2>
                        <div className="text-element"></div>
                        <p className="paragraph1">
                            People can use PazeTech solutions to quickly pay for street and garage parking
                            without having to use a meter or kiosk.
                        </p>
                    </div>
                    <div className="base-div">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <div className="icon-holder">
                                            <img src={iconc} alt="" />
                                        </div>
                                        <h4 className="header4">
                                            PazeTech <br></br>Cloud based Solution
                                        </h4>
                                    </th>
                                    <th className="header5">Vs</th>
                                    <th>
                                        <div className="icon-holder">
                                            <img src={iconal} alt="" />
                                        </div>
                                        <h4 className="header4">
                                            On Premise
                                            <br></br>Solution
                                        </h4>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="paragraph3">Short</td>
                                    <td className="header6">IMPLEMENTATION TIME</td>
                                    <td className="paragraph3">In general, significantly longer</td>
                                </tr>
                                <tr>
                                    <td className="paragraph3">Low</td>
                                    <td className="header6">UPFRONT INVESTMENT</td>
                                    <td className="paragraph3">High</td>
                                </tr>
                                <tr>
                                    <td className="paragraph3">No</td>
                                    <td className="header6">ADDITIONAL HARDWARE / IT COSTS</td>
                                    <td className="paragraph3">Yes</td>
                                </tr>
                                <tr>
                                    <td className="paragraph3">Predictable</td>
                                    <td className="header6">Total Cost of Ownership</td>
                                    <td className="paragraph3">IUnpredictable</td>
                                </tr>
                                <tr>
                                    <td className="paragraph3">Ongoing Upgrades</td>
                                    <td className="header6">Feature Enhancements</td>
                                    <td className="paragraph3">Feature Lock-In</td>
                                </tr>
                                <tr>
                                    <td className="paragraph3">Monthly</td>
                                    <td className="header6">Pricing Model</td>
                                    <td className="paragraph3">Need In-House IT Team</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
            </div>
        );
    }
}
