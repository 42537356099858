import React from "react";

export default class ScheduleDemo extends React.Component {
  constructor(props) {
    super(props);

    this.submitHandler = this.submitHandler.bind(this);
  }

  submitHandler = () => {
    let elFirstName = document.getElementById("first-name");
    let elLastName = document.getElementById("last-name");
    let elEmail = document.getElementById("email");
    let elProduct = document.getElementById("product");

    let arrList = {};

    [elFirstName, elLastName, elEmail, elProduct].forEach(function(element) {
      arrList[element.name] = element.value;
    });

    console.log(arrList);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(arrList),
    };
    fetch("/api/request-a-demo", requestOptions).then((response) => {
      console.log(response.body);
      elFirstName.value = "";
      elLastName.value = "";
      elEmail.value = "";
      elProduct.value = "";
    });
  };
  render() {
    let headerText = this.props.data;
    return (
      <section className="contact-us-section">
        <div className="base-div ">
          <div className="content-section">
            <h3 className="header2">{headerText}</h3>
            <div className="text-element"></div>
            <p className="paragraph1">
              Our clients get the best results when they have our team dedicated
              to their business for extended periods of time. Ready to move
              forward?
            </p>
          </div>
          <div className="input-holder">
            <input
              type="text"
              placeholder="First Name"
              name="fname"
              id="first-name"
            />
            <input
              type="text"
              placeholder="Last Name"
              name="lname"
              id="last-name"
            />
            <select name="product" id="product">
              <option value="">Product you are interested in?</option>
              <option value="off-site-airport-parking">
                Off Site Airport Parking
              </option>
              <option value="airport-rideshare">Airport Rideshare</option>
              <option value="valet-parking">Valet Parking</option>
              <option value="fleet-tracking">Fleet Tracking</option>
              <option value="pay-by-phone">Pay By Phone</option>
            </select>
            <input type="text" placeholder="Email id" name="email" id="email" />
            <button
              className="primary-button paragraph2"
              onClick={this.submitHandler}
            >
              Submit
            </button>
          </div>
        </div>
      </section>
    );
  }
}
