import React from "react";

export default class ContactUs extends React.Component {
  constructor(props) {
    super(props);

    //this.mapLocationHandler = this.mapLocationHandler.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
  }

  // mapLocationHandler = () => {
  //     console.log("d");

  //     window.open(
  //         "https://www.google.com/maps/place/Blue+Sky+Airport+Parking/@33.4205055,-111.9812151,17z/data=!4m5!3m4!1s0x872b0ee0558845e1:0x3d05b6a104717c23!8m2!3d33.4204787!4d-111.9797774",
  //         "_blank",
  //     );
  // };
  sendMessage = () => {
    let elFirstName = document.getElementById("first-name");
    let elLastName = document.getElementById("last-name");
    let elMessage = document.getElementById("message");
    let elEmail = document.getElementById("email");
    let elAdditionalDetails = document.getElementById("additional-details");
    let elProduct = document.getElementById("product");

    let arrList = {};

    [
      elFirstName,
      elLastName,
      elMessage,
      elEmail,
      elAdditionalDetails,
      elProduct,
    ].forEach(function(element) {
      arrList[element.name] = element.value;
    });

    console.log(arrList);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(arrList),
    };
    fetch("/api/request-a-demo", requestOptions).then((response) => {
      console.log(response.body);
      elFirstName.value = "";
      elLastName.value = "";
      elMessage.value = "";
      elEmail.value = "";
      elAdditionalDetails.value = "";
      elProduct.value = "";
    });
  };
  render() {
    let mapImage = "image/map.png";
    let iconal = "image/icon-al.png";
    let iconac = "image/icon-ac.png";

    return (
      <div className="contact-us">
        <section className="contact-us-section contact-details">
          <div className="base-div">
            <div className="content-section">
              <h3 className="header2">Become a client!</h3>
              <div className="text-element"></div>
              <p className="paragraph1">
                We’re here to help and answer any question you might have. We
                look forward to hearing from you{" "}
              </p>
            </div>
            <div className="input-holder">
              <div className="name-holder">
                <span>
                  <label className="label2">FIRST NAME</label>
                  <input
                    type="text"
                    placeholder="First Name"
                    id="first-name"
                    name="fname"
                  />
                </span>
                <span>
                  <label className="label2">LAST NAME</label>
                  <input
                    type="text"
                    placeholder="Last Name"
                    id="last-name"
                    name="lname"
                  />
                </span>
              </div>
              <span>
                <label className="label2">EMAIL</label>
                <input
                  type="text"
                  placeholder="Email"
                  id="email"
                  name="email"
                />
              </span>
              <span>
                <label className="label2">Products you are interested in</label>
                <select name="product" id="product">
                  <option value="">Product you are interested in?</option>
                  <option value="off-site-airport-parking">
                    Off Site Airport Parking
                  </option>
                  <option value="airport-rideshare">Airport Rideshare</option>
                  <option value="valet-parking">Valet Parking</option>
                  <option value="fleet-tracking">Fleet Tracking</option>
                  <option value="pay-by-phone">Pay By Phone</option>
                </select>
              </span>
              <span>
                <label className="label2">MESSAGE</label>
                <input
                  type="text"
                  placeholder="Message"
                  id="message"
                  name="message"
                />
              </span>
              <span>
                <label className="label2">ADDITIONAL DETAILS</label>
                <textarea
                  name="additionaldetails"
                  cols="30"
                  rows="4"
                  placeholder="Additional details"
                  id="additional-details"
                ></textarea>
              </span>
              <button
                className="primary-button paragraph2"
                onClick={this.sendMessage}
              >
                Send Message
              </button>
            </div>
          </div>
        </section>
        {/* <section className="location-section">
                    <div className="map-holder" onClick={this.mapLocationHandler}>
                        <img src={mapImage} alt="" />
                    </div>
                    <div className="content-holder">
                        <h4 className="header4">Our Office Location</h4>
                        <div className="info">
                            <div className="icon-holder">
                                <img src={iconal} alt="" />
                            </div>
                            <div className="text-holder">
                                <p className="paragraph1">PazeTech LLC</p>
                                <p className="paragraph3">3025 S 48th St,</p>
                                <p className="paragraph3">Phoenix, AZ 85040</p>
                            </div>
                        </div>
                        <div className="info">
                            <div className="icon-holder">
                                <img src={iconac} alt="" />
                            </div>
                            { <div className="text-holder">
                                <p className="paragraph1">480-329-7748</p>
                            </div>}
                        </div>
                    </div>
                </section> */}
      </div>
    );
  }
}
