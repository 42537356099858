import React from "react";
import * as Content from "./Content"
let hp = Content.HomePage

export default class Index extends React.Component {
    constructor(props) {
        super(props);
        
        this.onClickHandler = this.onClickHandler.bind(this);
    }

    onClickHandler = currentScreen => {
        this.props.onchange(currentScreen);
    };
    render() {
        let heroImage1x = "image/homepage-cover.png";
        let heroImage2x = "image/homepage-cover@2x.png";
        let pazetechServices = "image/pazetech-services.png";
        let webDesign = "image/web-design.png";
        let mobileApps = "image/mobile-apps.png";
        let customSoftwareSolutions = "image/custom-software-solutions.png";
        let airportParking = "image/off-airport-parking-illustration.png";
        let airportRideshare = "image/airport-rideshare-illustration.png";
        let valetParking = "image/valet-parking-illustration.png";
        let fleetTracking = "image/fleet-tracking-illustration.png";
        let payByPhone = "image/pay-by-phone-illustration.png";
        let offAirportParkingIcon = "image/off-airport-parking-icon.png";
        let airportRideshareIcon = "image/airport-rideshare-icon.png";
        let valetParkingIcon = "image/valet-parking-icon.png";
        let fleetTrackingIcon = "image/fleet-tracking-icon.png";
        let payByPhoneIcon = "image/pay-by-phone-icon.png";
        let businessRequirements = "image/business-requirements.png";
        let webDevelopment = "image/web-development.png";
        let productLaunch = "image/product-launch.png";
        let increaseRevenue = "image/increase-revenue.png";
        let streamlineOperation = "image/streamline-operation.png";
        let improveCustomerService = "image/improve-customer-service.png";
        let paymentSecure = "image/payment-secure.png";
        let benefitsSaasModel = "image/benefits-saas-model.png";
        let blueskyLogo = "image/bluesky-logo.png";
        
        return (
            <div className="index-section">
                <section className="hero-section">
                    <div className="hero-image">
                        <img className="hide-md" src={heroImage1x} alt="" />
                        <img className="hide-xs" src={heroImage2x} alt="" />
                    </div>
                    <div className="base-div ">
                        <span>
                            <h1 className="header1">Solution for Parking & Shuttling Services</h1>
                            <p className="paragraph1">
                                {hp["Solution_for_Parking_&_Shuttling_Services"]}
                            </p>
                        </span>
                    </div>
                </section>
                <section className="who-section">
                    <div className="base-div ">
                        <div className="section-content">
                            <h2 className="header2">Who we are?</h2>
                            <div className="text-element"></div>
                            <p className="paragraph1">
                                {hp.Who_we_Are}
                            </p>
                        </div>
                        <div className="section-image">
                            <img src={pazetechServices} alt="" />
                        </div>
                    </div>
                </section>
                <section className="what-section">
                    <div className="head-base-div ">
                        <h2 className="header2">What we do?</h2>
                        <div className="text-element"></div>
                        <p className="paragraph1">
                            {hp.What_do_We_Do}
                        </p>
                    </div>
                    <div className="base-div ">
                        <ul className="list">
                            <li className="list-item">
                                <div className="icon-holder">
                                    <img src={webDesign} alt="" />
                                </div>
                                <h5 className="header5">Website Design & Development</h5>
                                <p className="paragraph3">
                                    {hp["Website_Design_&_Development"]}
                                </p>
                            </li>
                            <li className="list-item">
                                <div className="icon-holder">
                                    <img src={mobileApps} alt="" />
                                </div>
                                <h5 className="header5">iOS & Android Mobile Apps</h5>
                                <p className="paragraph3">
                                    {hp["iOS_&_Android_Mobile_Apps"]}
                                </p>
                            </li>
                            <li className="list-item">
                                <div className="icon-holder">
                                    <img src={customSoftwareSolutions} alt="" />
                                </div>
                                <h5 className="header5">Custom Software Solutions</h5>
                                <p className="paragraph3">
                                    {hp.Custom_Software_Solutions}
                                </p>
                            </li>
                        </ul>
                    </div>
                </section>
                <section className="case-study-section multi-color-bg">
                    <div className="head-base-div ">
                        <h2 className="header2">Solutions we provide</h2>
                        <div className="text-element"></div>

                        <p className="paragraph1">
                            {hp.Solutions_We_Provide}
                        </p>
                    </div>
                </section>
                <section className="case-study-section padding-top-40">
                    <div className="base-div ">
                        <div className="image-holder">
                            <img src={airportParking} alt="" />
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={offAirportParkingIcon} alt="" />
                            </div>
                            <h3 className="header3">Off Airport Parking</h3>
                            <p className="paragraph1">
                                {hp.Off_Airport_Parking}
                            </p>
                            {/* <p className="paragraph1">Starting from $5000 onwards!</p> */}
                            <button onClick={() => this.onClickHandler("airportparking")} className="secondary-button">
                                LEARN MORE
                            </button>
                        </div>
                    </div>
                    <div className="base-div flip">
                        <div className="image-holder">
                            <img src={airportRideshare} alt="" />
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={airportRideshareIcon} alt="" />
                            </div>
                            <h3 className="header3">Airport Rideshare</h3>
                            <p className="paragraph1">
                                {hp.Airport_Rideshare}
                            </p>
                            <button onClick={() => this.onClickHandler("airportshuttle")} className="secondary-button">
                                LEARN MORE
                            </button>
                        </div>
                    </div>
                    <div className="base-div ">
                        <div className="image-holder">
                            <img src={valetParking} alt="" />
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={valetParkingIcon} alt="" />
                            </div>
                            <h3 className="header3">Valet Parking</h3>
                            <p className="paragraph1">
                                {hp.Valet_Parking}
                            </p>
                            <button onClick={() => this.onClickHandler("valetparking")} className="secondary-button">
                                LEARN MORE
                            </button>
                        </div>
                    </div>
                    <div className="base-div flip">
                        <div className="image-holder">
                            <img src={fleetTracking} alt="" />
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={fleetTrackingIcon} alt="" />
                            </div>
                            <h3 className="header3">Fleet Tracking</h3>
                            <p className="paragraph1">
                                {hp.Fleet_Tracking}
                            </p>
                            <button onClick={() => this.onClickHandler("fleettracking")} className="secondary-button">
                                LEARN MORE
                            </button>
                        </div>
                    </div>
                    <div className="base-div ">
                        <div className="image-holder">
                            <img src={payByPhone} alt="" />
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={payByPhoneIcon} alt="" />
                            </div>
                            <h3 className="header3">Pay by Phone</h3>
                            <p className="paragraph1">
                                {hp.Pay_by_Phone}
                            </p>
                            <button onClick={() => this.onClickHandler("paybyphone")} className="secondary-button">
                                LEARN MORE
                            </button>
                        </div>
                    </div>
                </section>
                <section className="how-work-section">
                    <div className="head-base-div">
                        <h2 className="header2">How we work?</h2>
                        <div className="text-element"></div>
                        <p className="paragraph1">
                            {hp.How_we_Work}
                        </p>
                    </div>
                    <div className="base-div ">
                        <ul className="list">
                            <li className="list-item">
                                <div className="icon-holder">
                                    <img src={businessRequirements} alt="" />
                                </div>
                                <h5 className="header5">Provide your Business Requirements</h5>
                                <p className="paragraph3">
                                    {hp.Provide_Your_Business_Requirements}
                                </p>
                            </li>
                            <li className="list-item">
                                <div className="icon-holder">
                                    <img src={webDevelopment} alt="" />
                                </div>
                                <h5 className="header5">Design & Development</h5>
                                <p className="paragraph3">
                                    {hp["Design_&_Development"]}
                                </p>
                            </li>
                            <li className="list-item">
                                <div className="icon-holder">
                                    <img src={productLaunch} alt="" />
                                </div>
                                <h5 className="header5">Product Launch</h5>
                                <p className="paragraph3">
                                    {hp.Product_Launch}
                                </p>
                            </li>
                        </ul>
                    </div>
                </section>
                <section className="what-makes-section">
                    <div className="base-div ">
                        <div className="content-section">
                            <h3 className="header3">What makes us the best choice for businesses</h3>
                            <div className="text-element"></div>
                            <p className="paragraph1">
                                {hp.What_Makes_Us_the_Best_Choice_for_Businesses}
                            </p>                           
                        </div>
                        <ul className="list">
                            <li className="list-item">
                                <div className="icon-holder">
                                    <img src={increaseRevenue} alt="" />
                                </div>
                                <h5 className="header5">Increase Revenue</h5>
                            </li>
                            <li className="list-item">
                                <div className="icon-holder">
                                    <img src={streamlineOperation} alt="" />
                                </div>
                                <h5 className="header5">Streamline Operations</h5>
                            </li>
                            <li className="list-item">
                                <div className="icon-holder">
                                    <img src={improveCustomerService} alt="" />
                                </div>
                                <h5 className="header5">Improve Customer Service</h5>
                            </li>
                            <li className="list-item">
                                <div className="icon-holder">
                                    <img src={paymentSecure} alt="" />
                                </div>
                                <h5 className="header5">Secure Payments</h5>
                            </li>
                        </ul>
                    </div>
                </section>
                <section className="benefit-section">
                    <div className="head-base-div">
                        <h2 className="header2">Development & Pricing Model</h2>
                        <div className="text-element"></div>
                        {/* <p className="paragraph1">PazeTech helps millions of people easily find and pay for parking on their mobile devices.</p> */}
                    </div>
                    <div className="base-div">
                        <div className="image-holder">
                            <img src={benefitsSaasModel} alt="" />
                        </div>
                    </div>
                </section>
                {/* <section className="client-section">
                    <div className="head-base-div">
                        <h2 className="header2">Our clients love what we do</h2>
                        <div className="text-element"></div>
                    </div>
                    <div className="base-div">
                        <div id="myCarousel" className="carousel slide carousel-fade salesCarousel" data-ride="carousel">
                            <div className="carousel-inner list" role="listbox">
                                <section className="item" id="slide-1">
                                    <div className="list-item">
                                        <div className="image-holder">
                                            <img src={blueskyLogo} alt="" />
                                        </div>
                                        <div className="content-holder">
                                            “Massa tincidunt nunc pulvinar sapien et ligula. Volutpat ac tincidunt vitae semper quis lectus nulla at volutpat. Mi eget mauris pharetra et ultrices
                                            neque”
                                        </div>
                                    </div>
                                </section>
                                <section className="item active" id="slide-1">
                                    <div className="list-item">
                                        <div className="image-holder">
                                            <img src={blueskyLogo} alt="" />
                                        </div>
                                        <div className="content-holder">
                                            “We are the leader in smart parking and mobility solutions. We connect you with our audience of over 15 million people looking for parking every single
                                            day.”
                                        </div>
                                    </div>
                                </section>
                                <section className="item" id="slide-1">
                                    <div className="list-item">
                                        <div className="image-holder">
                                            <img src={blueskyLogo} alt="" />
                                        </div>
                                        <div className="content-holder">
                                            “Massa tincidunt nunc pulvinar sapien et ligula. Volutpat ac tincidunt vitae semper quis lectus nulla at volutpat. Mi eget mauris pharetra et ultrices
                                            neque”
                                        </div>
                                    </div>
                                </section>
                                <section className="item" id="slide-1">
                                    <div className="list-item">
                                        <div className="image-holder">
                                            <img src={blueskyLogo} alt="" />
                                        </div>
                                        <div className="content-holder">
                                            “We are the leader in smart parking and mobility solutions. We connect you with our audience of over 15 million people looking for parking every single
                                            day.”
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <ol className="carousel-indicators">
                                <li data-target="#myCarousel" data-slide-to="0" className=""></li>
                                <li data-target="#myCarousel" data-slide-to="1" className="active"></li>
                                <li data-target="#myCarousel" data-slide-to="2" className=""></li>
                                <li data-target="#myCarousel" data-slide-to="3" className=""></li>
                            </ol>
                        </div>
                    </div>
                </section> */}
            </div>
        );
    }
}
