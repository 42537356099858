import React from "react";
import { AirportShuttle as temp } from "./Content"
const as = temp
export default class AirportShuttle extends React.Component {
    render() {
        let heroImage1x = "image/airport-rideshare.png";
        let heroImage2x = "image/airport-rideshare@2x.png";
        let webDesign = "image/web-design.png";
        let mobileApps = "image/mobile-apps.png";
        let customSoftwareSolutions = "image/custom-software-solutions.png";
        let tickMark = "image/tick-mark.png";
        let parkingSofwares = "image/parking-sofwares.png";
        let whyAirportShuttleAutomation = "image/why-airport-shuttle-automation.png";
        let howRideShareWorks = "image/how-rideshare-works.png";
        // let iconz = "image/icon-z.png";
        // let iconao = "image/icon-ao.png";
        // let iconan = "image/icon-an.png";
        // let icond = "image/icon-d.png";
        // let iconam = "image/icon-am.png";
        // let iconr = "image/icon-r.png";
        // let iconai = "image/icon-ai.png";
        // let iconu = "image/icon-u.png";
        // let iconad = "image/icon-ad.png";
        // let iconf = "image/icon-f.png";
        // let iconp = "image/icon-p.png";
        // let iconq = "image/icon-q.png";
        // let iconac = "image/icon-ac.png";

        return (
            <div className="airport-shuttle-section">
                <section className="hero-section">
                    <div className="hero-image">
                        <img className="hide-md" src={heroImage1x} alt="" />
                        <img className="hide-xs" src={heroImage2x} alt="" />
                    </div>
                    <div className="base-div ">
                        <span>
                            <h1 className="header1">Digitalize Airport Shuttle Operation</h1>
                            <p className="paragraph1">
                                {as["Digitize Airport Shuttle Operation"]}
                            </p>
                        </span>
                    </div>
                </section>
                <section className="problem-solution-section">
                    <div className="base-div">
                        <section className="problem-section">
                            <h3 className="header3">What Problems Shuttle Operators faces?</h3>
                            <p className="paragraph1">{as["What Problem Airport Rdeshare Operator Faces?"].header}</p>
                            <ul className="list">
                                {as["What Problem Airport Rdeshare Operator Faces?"].items.map(i => {
                                    return (
                                        <li className="list-item paragraph1">{i}</li>
                                    )
                                })}
                            </ul>
                        </section>
                        <section className="solution-section">
                            <h3 className="header3">How our solutions will overcome those problems?</h3>
                            <p className="paragraph1">{as["How our Solution will over come those problems?"].header}</p>
                            <ul className="list">
                                {as["How our Solution will over come those problems?"].items.map(i => {
                                    return (
                                        <li className="list-item paragraph1">{i}</li>
                                    )
                                })}
                            </ul>
                        </section>
                    </div>
                </section>
                <section className="what-section">
                    <div className="head-base-div ">
                        <h2 className="header2">What we do?</h2>
                        <div className="text-element"></div>
                        <p className="paragraph1">
                            {as["What do We Do?"]}
                        </p>
                    </div>
                    <div className="base-div ">
                        <ul className="list">
                            <li className="list-item">
                                <div className="icon-holder">
                                    <img src={webDesign} alt="" />
                                </div>
                                <h5 className="header5">Website Design & Development</h5>
                                <p className="paragraph3">
                                    {as["Website Design & Development"]}
                                </p>
                            </li>
                            <li className="list-item">
                                <div className="icon-holder">
                                    <img src={mobileApps} alt="" />
                                </div>
                                <h5 className="header5">iOS & Android Mobile Apps</h5>
                                <p className="paragraph3">
                                    {as["iOS & Android Mobile Apps"]}
                                </p>
                            </li>
                            <li className="list-item">
                                <div className="icon-holder">
                                    <img src={customSoftwareSolutions} alt="" />
                                </div>
                                <h5 className="header5">Custom Software Solutions</h5>
                                <p className="paragraph3">
                                    {as["Custom Software Solutions"]}
                                </p>
                            </li>
                        </ul>
                    </div>
                </section>
                <section className="why-section">
                    <div className="head-base-div">
                        <h2 className="header2">Why Choose us for Aiport Shuttle Automation</h2>
                        <div className="text-element"></div>
                        <p className="paragraph1">
                            {as["Why Choose us for Airport Rideshare Automation"]}
                        </p>
                    </div>
                    <div className="base-div">
                        <div className="image-holder ">
                            <img src={whyAirportShuttleAutomation} alt="" />
                        </div>
                    </div>
                </section>
                <section className="image-representation-section">
                    <div className="head-base-div">
                        <h3 className="header3">How Rideshare Works?</h3>
                        <p className="paragraph2">
                            {as["How Airport Rideshare Business Works?"]}
                        </p>
                    </div>
                    <div className="base-div">
                        <div className="image-holder">
                            <img src={howRideShareWorks} alt="" />
                        </div>
                    </div>
                </section>
                <section className="feature-section color-bg">
                    <div className="base-div">
                        <div className="content-holder">
                            <h6 className="header6">What owners / managers get?</h6>
                            <h2 className="header2">Airport Shuttle Automation</h2>
                            <div className="text-element"></div>
                            <p className="paragraph1">
                                {as["Airport Rideshare Automation"].header}
                            </p>
                            <h5 className="header5">Key Features</h5>
                            <ul className="list">
                                {as["Airport Rideshare Automation"].items.map(i=>{
                                    return (
                                        <li className="list-item">
                                            <div className="icon-holder">
                                                <img src={tickMark} alt="" />
                                            </div>
                                            <p className="paragraph3">{i}</p>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        <div className="image-holder">
                            <img src={parkingSofwares} alt="" />
                        </div>
                    </div>
                </section>
                <section className="multi-point-section color-bg">
                    <div className="head-base-div">
                        <ul className="nav nav-pills">
                            <li className="active">
                                <a className="paragraph6" data-toggle="pill" href="#managermodule">
                                    Manager <span>Module</span>
                                </a>
                            </li>
                            <li>
                                <a className="paragraph6" data-toggle="pill" href="#drivermodule">
                                    Driver <span>Module</span>
                                </a>
                            </li>
                            <li>
                                <a className="paragraph6" data-toggle="pill" href="#customermodule">
                                    Customer <span>Module</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <div id="managermodule" className="base-div tab-pane fade active in">   
                            <p className="paragraph1">{as["Management Module"].header}</p>
                            {as["Management Module"].items.map(i=>{
                                return (
                                    <div className="content-holder">
                                        <div className="icon-holder">
                                            <img src={i.icon} alt="" />
                                        </div>
                                        <div>
                                            <h5 className="header5">{i.title}</h5>
                                            <p className="paragraph3">{i.description}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div id="drivermodule" className="base-div tab-pane fade">
                            <p className="paragraph1">{as["Driver Module"].header}</p>
                            {as["Driver Module"].items.map(i=>{
                                return (
                                    <div className="content-holder">
                                        <div className="icon-holder">
                                            <img src={i.icon} alt="" />
                                        </div>
                                        <div>
                                            <h5 className="header5">{i.title}</h5>
                                            <p className="paragraph3">{i.description}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div id="customermodule" className="base-div tab-pane fade">
                            <p className="paragraph1">{as["Customer Module"].header}</p>
                            {as["Customer Module"].items.map(i=>{
                                return (
                                    <div className="content-holder">
                                        <div className="icon-holder">
                                            <img src={i.icon} alt="" />
                                        </div>
                                        <div>
                                            <h5 className="header5">{i.title}</h5>
                                            <p className="paragraph3">{i.description}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </section>
                <section className="feature-section white-bg">
                    <div className="base-div">
                        <div className="content-holder">
                            <h6 className="header6">What your customers get?</h6>
                            <h2 className="header2">Customer App</h2>
                            <div className="text-element"></div>
                            <p className="paragraph1">
                               {as["Customer Facing App"]} 
                            </p>
                            <h5 className="header5">Key Features</h5>
                            <ul className="list double-list">
                                <li className="list-item">
                                    <div className="icon-holder">
                                        <img src={tickMark} alt="" />
                                    </div>
                                    <p className="paragraph3">Profile Management</p>
                                </li>
                                <li className="list-item">
                                    <div className="icon-holder">
                                        <img src={tickMark} alt="" />
                                    </div>
                                    <p className="paragraph3">Rewards & Loyalty Account</p>
                                </li>
                                <li className="list-item">
                                    <div className="icon-holder">
                                        <img src={tickMark} alt="" />
                                    </div>
                                    <p className="paragraph3">Manage Reservation</p>
                                </li>
                                <li className="list-item">
                                    <div className="icon-holder">
                                        <img src={tickMark} alt="" />
                                    </div>
                                    <p className="paragraph3">Real-Time Notifcation</p>
                                </li>
                                <li className="list-item">
                                    <div className="icon-holder">
                                        <img src={tickMark} alt="" />
                                    </div>
                                    <p className="paragraph3">Mobile Checkout</p>
                                </li>
                                <li className="list-item">
                                    <div className="icon-holder">
                                        <img src={tickMark} alt="" />
                                    </div>
                                    <p className="paragraph3">Integrated tip to Drivert</p>
                                </li>
                                <li className="list-item">
                                    <div className="icon-holder">
                                        <img src={tickMark} alt="" />
                                    </div>
                                    <p className="paragraph3">Real-Time Shuttle Tracking</p>
                                </li>
                                <li className="list-item">
                                    <div className="icon-holder">
                                        <img src={tickMark} alt="" />
                                    </div>
                                    <p className="paragraph3">Available on iOs and Android</p>
                                </li>
                                <li className="list-item">
                                    <div className="icon-holder">
                                        <img src={tickMark} alt="" />
                                    </div>
                                    <p className="paragraph3">Integrated Chat - Between Dispatcher and Agent</p>
                                </li>
                            </ul>
                        </div>
                        <div className="image-holder">
                            <img src={parkingSofwares} alt="" />
                        </div>
                    </div>
                </section>
                <section className="multi-point-section white-bg">
                    <div className="head-base-div">
                        <h3 className="paragraph6">Shuttle Reservation Benefits</h3>
                    </div>
                    <div className="base-div">
                        {as["Shuttle Reservation Benefits"].map(i=>{
                            return (
                                <div className="content-holder">
                                    <div className="icon-holder">
                                        <img src={i.icon} alt="" />
                                    </div>
                                    <div>
                                        <h5 className="header5">{i.title}:</h5>
                                        <p className="paragraph3">{i.description}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </section>
            </div>
        );
    }
}
