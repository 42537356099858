import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import Index from "./Index";
import OffSiteAirportParking from "./OffSiteAirportParking";
import AirportShuttle from "./AirportShuttle";
import ValetParking from "./ValetParking";
import FleetTracking from "./FleetTracking";
import PaybyPhone from "./PaybyPhone";
import ContactUs from "./ContactUs";
import Pricing from "./Pricing";
import AboutUs from "./AboutUs";
import ScheduleDemo from "./ScheduleDemo";

export default class Main extends React.Component {
    constructor(props) {
        super(props);
        var initScreen = "index"
        if(window.location.href) {
            this.allScreens.forEach(screen => {
                if(window.location.href.endsWith(screen)) {
                    console.log("Initing to ", screen)
                    initScreen = screen
                }
            })            
        }
        this.state = {
            screen: initScreen,
        };
    }
    componentDidMount = () => {
        this.onClickHandler = this.onClickHandler.bind(this);
    };

    allScreens = ["index", 
    //"aboutus", 
    "airportparking", "airportshuttle","valetparking", "fleettracking", "paybyphone", "contactus", "pricing"]

    onClickHandler = currentScreen => {
        document.getElementById("hiddendiv").focus();
        // window.location.href = currentScreen;

        this.setState(()=>{ 
            console.log("Navigating to ", currentScreen)
            window.history.pushState("", currentScreen, `/${currentScreen}`);
            return { screen: currentScreen }
        });
    };

    render() {
        const strCurrentScreen = this.state.screen;        

        let boolIndex = strCurrentScreen === "index" ? true : false;
        //let boolAboutus = strCurrentScreen === "aboutus" ? true : false;
        let boolOffSiteAirportParking = strCurrentScreen === "airportparking" ? true : false;
        let boolAirportShuttle = strCurrentScreen === "airportshuttle" ? true : false;
        let boolValetParking = strCurrentScreen === "valetparking" ? true : false;
        let boolFleetTracking = strCurrentScreen === "fleettracking" ? true : false;
        let boolPaybyPhone = strCurrentScreen === "paybyphone" ? true : false;
        let boolContactUs = strCurrentScreen === "contactus" ? true : false;
        let boolPricing = strCurrentScreen === "pricing" ? true : false;
        let boolStateChange = strCurrentScreen && strCurrentScreen !== "" ? true : false;
        let headerText;
        let boolShowContactUsForm = true;

        if (boolIndex || boolPricing) {
            headerText = "Become a client!";
        } else {
            headerText = "Schedule a demo!";
        }
        if (boolContactUs) { // || boolAboutus
            boolShowContactUsForm = false;
        }
        return (
            <div className="main-container">
                <button id="hiddendiv"></button>
                {boolStateChange && <Header onchange={this.onClickHandler} activescreen={strCurrentScreen}></Header>}
                {boolIndex && <Index onchange={this.onClickHandler}></Index>}
                {/* {boolAboutus && <AboutUs onchange={this.onClickHandler}></AboutUs>} */}
                {boolOffSiteAirportParking && <OffSiteAirportParking onchange={this.onClickHandler}></OffSiteAirportParking>}
                {boolAirportShuttle && <AirportShuttle onchange={this.onClickHandler}></AirportShuttle>}
                {boolValetParking && <ValetParking onchange={this.onClickHandler}></ValetParking>}
                {boolFleetTracking && <FleetTracking></FleetTracking>}
                {boolPaybyPhone && <PaybyPhone onchange={this.onClickHandler}></PaybyPhone>}
                {boolContactUs && <ContactUs onchange={this.onClickHandler}></ContactUs>}
                {boolPricing && <Pricing onchange={this.onClickHandler}></Pricing>}
                {boolShowContactUsForm && <ScheduleDemo data={headerText}></ScheduleDemo>}
                <Footer onchange={this.onClickHandler}></Footer>
            </div>
        );
    }
}
