import React from "react";
import { PayByPhone as pp } from "./Content"

export default class PaybyPhone extends React.Component {
    render() {
        let heroImage1x = "image/pay-phone-cover.png";
        let heroImage2x = "image/pay-phone-cover@2x.png";
        let webDesign = "image/web-design.png";
        let mobileApps = "image/mobile-apps.png";
        let customSoftwareSolutions = "image/custom-software-solutions.png";
        let parkNow = "image/park-now.png";
        let reserveLater = "image/reserve-for-later.png";
        let tickMark = "image/tick-mark.png";
        let parkingSofwares = "image/parking-sofwares.png";

        let iconab = "image/icon-ab.png";
        let iconx = "image/icon-x.png";
        let icony = "image/icon-y.png";
        let iconz = "image/icon-z.png";
        let iconv = "image/icon-v.png";
        let iconaa = "image/icon-aa.png";
        //let iconb = "image/icon-b.png";
        //let iconad = "image/icon-ad.png";
        let icono = "image/icon-o.png";
        let icond = "image/icon-d.png";
        let icont = "image/icon-t.png";
        let iconp = "image/icon-p.png";
        let iconae = "image/icon-ae.png";
        let iconaj = "image/icon-j.png";
        return (
            <div className="airport-shuttle-section">
                <section className="hero-section">
                    <div className="hero-image">
                        <img className="hide-md" src={heroImage1x} alt="" />
                        <img className="hide-xs" src={heroImage2x} alt="" />
                    </div>
                    <div className="base-div ">
                        <span>
                            <h1 className="header1">Pay by Phone</h1>
                            <p className="paragraph1">
                                {pp["Pay by Phone"]}
                            </p>
                        </span>
                    </div>
                </section>
                <section className="problem-solution-section">
                    <div className="base-div">
                        <section className="problem-section">
                            <h3 className="header3">What issues Meter Parking Faces?</h3>
                            <p className="paragraph1">{pp["What issues Meter Parking Faces?"].header}</p>
                            <ul className="list">
                                {pp["What issues Meter Parking Faces?"].items.map(i=>{
                                    return (
                                        <li className="list-item paragraph1">{i}</li>        
                                    )
                                })}                                
                            </ul>
                        </section>
                        <section className="solution-section">
                            <h3 className="header3">How our solutions will overcome those problems?</h3>
                            <p className="paragraph1">{pp["How our Solution will over come those problems?"].header}</p>
                            <ul className="list">
                                {pp["How our Solution will over come those problems?"].items.map(i=>{
                                    return (
                                        <li className="list-item paragraph1">{i}</li>
                                    )
                                })}                                
                            </ul>
                        </section>
                    </div>
                </section>
                <section className="what-section">
                    <div className="head-base-div ">
                        <h2 className="header2">What we do?</h2>
                        <div className="text-element"></div>
                        <p className="paragraph1">
                            {pp["What we do?"]}
                        </p>
                    </div>
                    <div className="base-div ">
                        <ul className="list">
                            <li className="list-item">
                                <div className="icon-holder">
                                    <img src={webDesign} alt="" />
                                </div>
                                <h5 className="header5">Website Design & Development</h5>
                                <p className="paragraph3">
                                    {pp["Website Design & Development"]}
                                </p>
                            </li>
                            <li className="list-item">
                                <div className="icon-holder">
                                    <img src={mobileApps} alt="" />
                                </div>
                                <h5 className="header5">iOS & Android Mobile Apps</h5>
                                <p className="paragraph3">
                                    {pp["iOS & Android Mobile Apps"]}
                                </p>
                            </li>
                            <li className="list-item">
                                <div className="icon-holder">
                                    <img src={customSoftwareSolutions} alt="" />
                                </div>
                                <h5 className="header5">Custom Software Solutions</h5>
                                <p className="paragraph3">
                                    {pp["Custom Software Solutions"]}
                                </p>
                            </li>
                        </ul>
                    </div>
                </section>
                <section className="image-representation-section multi-white-bg">
                    <div className="head-base-div">
                        <h2 className="header2">How it Works?</h2>
                        <div className="text-element"></div>
                        <h3 className="header3">Park Now</h3>
                        <p className="paragraph2">
                            {pp["Park Now"]}
                        </p>
                    </div>
                    <div className="base-div">
                        <div className="image-holder">
                            <img src={parkNow} alt="" />
                        </div>
                    </div>
                    <div className="head-base-div">
                        <h3 className="header3">Reserve For Later</h3>
                        <p className="paragraph2">
                            {pp["Reserve for Later"]}
                        </p>
                    </div>
                    <div className="base-div">
                        <div className="image-holder">
                            <img src={reserveLater} alt="" />
                        </div>
                    </div>
                </section>
                <section className="feature-section white-bg">
                    <div className="base-div">
                        <div className="content-holder">
                            <h6 className="header6">What owners / managers get?</h6>
                            <h2 className="header2">Meter Parking Automation</h2>
                            <div className="text-element"></div>
                            {pp["Meter Parking Automation"].map(i=>{
                                return (
                                <p className="paragraph1">
                                    {i}
                                </p>
                                )
                            })}
                        </div>

                        <div className="image-holder">
                            <img src={parkingSofwares} alt="" />
                        </div>
                    </div>
                </section>
                <section className="multi-point-section">
                    <div className="head-base-div">
                        <h3 className="paragraph6">Automation Benefits</h3>
                    </div>
                    <div className="base-div">
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={icony} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Flexible Rate Mgmt</h5>
                                <p className="paragraph3">{pp["Flexible Rate Management"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconx} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Coupon Management</h5>
                                <p className="paragraph3">{pp["Coupon Management"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconz} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Set Black-out dates</h5>
                                <p className="paragraph3">{pp["Black out dates"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconv} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Revenue Mgmt</h5>
                                <p className="paragraph3">{pp["Revenu Management"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={icont} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Order Tracking</h5>
                                <p className="paragraph3">{pp["Complete Order Analytics"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconaa} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Customer Relationship Management</h5>
                                <p className="paragraph3">{pp["Customer Relationship Management"]}</p>
                            </div>
                        </div>

                        {/* <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconb} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Website Backend Mgmt for Lot Owners / Managers</h5>
                                <p className="paragraph3">Massa tincidunt nunc pulvinar sapien et ligula. Volutpat ac tincidunt vitae</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconad} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">PCI Compliance that securely processes customer credit card right through your website</h5>
                                <p className="paragraph3">Massa tincidunt nunc pulvinar sapien et ligula. Volutpat ac tincidunt vitae</p>
                            </div>
                        </div> */}
                    </div>
                </section>
                <section className="feature-section color-bg">
                    <div className="base-div">
                        <div className="content-holder">
                            <h6 className="header6">What your customers get?</h6>
                            <h2 className="header2">Customer Application</h2>
                            <div className="text-element"></div>
                            <p className="paragraph1">
                                {pp["Customer Facing Application"].header}
                            </p>
                            <h5 className="header5">Key Features</h5>
                            <ul className="list">
                                {pp["Customer Facing Application"].items.map(i=>{
                                    return (
                                        <li className="list-item">
                                        <div className="icon-holder">
                                            <img src={tickMark} alt="" />
                                        </div>
                                            <p className="paragraph3">{i}</p>
                                        </li>        
                                    )
                                })}                               
                            </ul>
                        </div>
                        <div className="image-holder">
                            <img src={parkingSofwares} alt="" />
                        </div>
                    </div>
                </section>
                <section className="multi-point-section color-bg">
                    <div className="head-base-div">
                        <h3 className="paragraph6">Pay by Phone Benefits</h3>
                    </div>
                    <div className="base-div">
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={icono} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Integrated Reservation System</h5>
                                <p className="paragraph3">{pp["Advance Reservation System"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconab} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Android based Parking Enforcement Mobile App for parking violations</h5>
                                <p className="paragraph3">{pp["Parking Enforcement"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={icond} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Extend your parking anytime</h5>
                                <p className="paragraph3">{pp["Extend your parking"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconaj} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Discounts & Reward Points</h5>
                                <p className="paragraph3">{pp["Coupoon & Discount Management"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconp} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Secure credit / debit card payments</h5>
                                <p className="paragraph3">{pp["Web / Mobile Checkout"]}</p>
                            </div>
                        </div>
                        <div className="content-holder">
                            <div className="icon-holder">
                                <img src={iconae} alt="" />
                            </div>
                            <div>
                                <h5 className="header5">Refer a friend</h5>
                                <p className="paragraph3">{pp["Refer a Friend"]}</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
